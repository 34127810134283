import './ItinerarySearchVenueListStyle.css'


const ItinerarySearchVenueList = ({venueSelectHandler, searchResultList}) =>{
    
    return(
        
        <div className="venue-search-list">
            {searchResultList.map((venue)=>
                (
                    <div className="search-venue-item" onClick={()=>venueSelectHandler(venue)}>
                        {venue.displayName}
                    </div>
                )
            )}  
        </div>
    )
}

export default ItinerarySearchVenueList