import { APIProvider, Map, AdvancedMarker, useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { useEffect, useState,useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faXmark,faCirclePlus,faRightLeft,faCircleMinus,faGripVertical} from '@fortawesome/free-solid-svg-icons'
import {DndContext, KeyboardSensor, PointerSensor, TouchSensor, closestCorners, useSensor, useSensors} from "@dnd-kit/core"
import {SortableContext, verticalListSortingStrategy,useSortable, arrayMove, sortableKeyboardCoordinates} from '@dnd-kit/sortable'
import {CSS} from '@dnd-kit/utilities'
import './MapComponent.css'
import netPoi from '../asset/images/netPoi.png';
import curPoi from '../asset/images/curPoi.png';
import surPoi from '../asset/images/surPoi.png';

const navigateLabel = ["From","To","Then to"]
const nagigateIconMarkers = ["A", "B", "C"]

/*global google*/

const MapComponent = ({ 
  position, venueList, setVenueList, 
  selectedVenue, setSelectedVenue, focusPosition, setFocusPosition,
  selectNavigationHandler, switchLocationHandler,
  navigationState, isOPenNavigator, openNavigatorHandler, 
  isCurrentPosition, setIsCurrentPosition, isCLClicked, setNavigationState,
  navigateLocations, setNavigateLocations, directionsRenderer, setDirectionsRenderer,
  surroundedVenueList, showSurroundedVenueList, isThreeLocation, setIsThreeLocation,  }) => {

  
  const [zoom, setZoom] = useState(15);

  const threeLocationHandler = () =>{
    console.log(isThreeLocation)
    setIsThreeLocation(!isThreeLocation)
  }

  useEffect(() => {
    if (isCLClicked) {
      setIsCurrentPosition(true);
      setZoom(20);
    } else {
      setIsCurrentPosition(false);
    }
  }, [isCLClicked]);

  const handleMarkerClick = (venue) => {
    setSelectedVenue(venue);
  };
 

  return (
    <div className='map-component-container'>
      <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY} libraries={[]} >
        <Map
          defaultZoom={15}
          zoom={zoom}
          defaultCenter={position}
          center={focusPosition}
          disableDefaultUI
          mapId={process.env.REACT_APP_MAP_ID}
          onDrag={()=>{ setFocusPosition(null) }}
          onZoomChanged={() => { setZoom(null) }}
          style={{width: '100%', height: '100%'}}
          className={'map-component'}
        >
          {isCurrentPosition && (
            <AdvancedMarker position={position}>
              <img src={curPoi} width={40} height={40} />
            </AdvancedMarker>
          )}
          
         
          {showSurroundedVenueList ?
            <MarkerSurPoi 
              showSurroundedVenueList={showSurroundedVenueList}
              surroundedVenueList = {surroundedVenueList}
              handleMarkerClick = {handleMarkerClick}
              selectedVenue = {selectedVenue}
            />
            :
            <MarkerNetPoi 
            handleMarkerClick = {handleMarkerClick}
            venueList = {venueList}
            />
          }
  
          <Directions 
            navigateLocations={navigateLocations} 
            isThreeLocation={isThreeLocation} 
            directionsRenderer={directionsRenderer}
            setDirectionsRenderer={setDirectionsRenderer}
            isOPenNavigator={isOPenNavigator}
            venueList={venueList}
            setVenueList={setVenueList}/>

        </Map>
      </APIProvider>

      {isOPenNavigator && 
      <div className='map-component-navigator'>
        <FontAwesomeIcon className='map-navigator-x-button' icon={faXmark} onClick={openNavigatorHandler}/>
          {!isThreeLocation ?
          <table>
          <tbody>
            <tr>
              <td><p className='navigator-icon-marker'>{nagigateIconMarkers[0]}</p></td>
              <td className='navigator-location'>
                <div className={navigationState === 0 ? "location  highlight" : "location"} onClick={()=>selectNavigationHandler(0)}>
                  {navigateLocations[0].location?.displayName || navigateLabel[0]}
                  </div>                                                                                                       
              </td>
            </tr>
            <tr>
              <td><p className='navigator-icon-marker'>{nagigateIconMarkers[1]}</p></td>
              <td className='navigator-location'>
                <div className={navigationState === 1 ? "location highlight" : "location"} onClick={()=>selectNavigationHandler(1)}>
                {navigateLocations[1].location?.displayName || navigateLabel[1]}
                  </div>
              </td>
              <td className='navigator-icon'>
                <FontAwesomeIcon icon={faRightLeft} onClick={switchLocationHandler}/>
              </td>
            </tr>
            <tr>
              <td></td>
              <td className='navigator-location'></td>
              <td className='navigator-icon'>
                <FontAwesomeIcon icon={faCirclePlus} 
                  onClick={()=>{threeLocationHandler();
                  setNavigationState(2);
                }}/>
              </td>
            </tr> 
        </tbody>
        </table> 
        :
        <DraggableTable 
          navigateLocations = {navigateLocations}
          navigationState = {navigationState}
          setNavigationState = {setNavigationState}
          selectNavigationHandler = {selectNavigationHandler}
          threeLocationHandler = {threeLocationHandler}
          setNavigateLocations = {setNavigateLocations}
        />   
        } 
      </div>}
    </div>
  );
}

const MarkerNetPoi = ({handleMarkerClick, venueList}) =>{
  
  const map = useMap()
  const[markers,setMarkers] = useState({})
  const markersRefNetPoi = useRef({});
  const clustererNetPoi = useRef(null)

  useEffect(()=>{
    clustererNetPoi.current?.clearMarkers()
    clustererNetPoi.current?.addMarkers(Object.values(markers))
  },[markers])

  useEffect(() => {
    if (!map) return;
    console.log("clusterer current netPoi: ")
    console.log(clustererNetPoi.current)
    if (!clustererNetPoi.current) {
      clustererNetPoi.current = new MarkerClusterer({
        map,
        markers: Object.values(markersRefNetPoi.current),
        renderer: {
          render: ({ count, position }) => new google.maps.Marker({
            icon:  {
              url: netPoi,
              scaledSize: new google.maps.Size(30, 30),
            } ,
            position,
            zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
          }),
          
        }
      })
    } else {
      console.log("pre created")
      clustererNetPoi.current.clearMarkers();
      clustererNetPoi.current.addMarkers(Object.values(markersRefNetPoi.current));
      console.log("new netPoi cluster cleared")
    }
    console.log(clustererNetPoi.current)
  }
  , [map,venueList]);



  const setMarkerRef = (marker, index) =>{
    if (!marker && !markersRefNetPoi.current[index]) return;
    if (marker && markersRefNetPoi.current[index]) return;

    if (marker) {
        markersRefNetPoi.current[index] = marker;
    } else {
        delete markersRefNetPoi.current[index];
    }

    if (clustererNetPoi.current) {
        clustererNetPoi.current.clearMarkers();
        clustererNetPoi.current.addMarkers(Object.values(markersRefNetPoi.current));
    }
  }
  

  return(
      (
        venueList.filter(item => item.isRecommended).map((recom,index) => {
          if(recom.notMarker) return;
          return (
            <AdvancedMarker
              key={recom.id}
              position={{ lat: recom.location.latitude, lng: recom.location.longitude }}
              title={recom.displayName}
              onClick={() => handleMarkerClick(recom)}
              ref={(marker)=>{setTimeout(() => setMarkerRef(marker,index), 100)}}
            >
              <img src={netPoi} width={25} height={25} />
            </AdvancedMarker>
          )
        })
      )
  )
}
const MarkerSurPoi = ({ 
  showSurroundedVenueList, 
  surroundedVenueList, 
  handleMarkerClick,  
  selectedVenue
}) =>{
  
  const map = useMap()
  const[markers,setMarkers] = useState({})
  const markersRef = useRef({});
  const clusterer = useRef(null)

  useEffect(()=>{
    clusterer.current?.clearMarkers()
    clusterer.current?.addMarkers(Object.values(markers))
  },[markers])

  useEffect(() => {
    if (!map) return;
    console.log("clusterer current surPoi: " +clusterer.current)
    if (!clusterer.current) {
      console.log("new surPoi cluster created")
      clusterer.current = new MarkerClusterer({
        map,
        markers: Object.values(markersRef.current),
        renderer: {
          render: ({ count, position }) => new google.maps.Marker({
            icon:  {
              url: surPoi,
              scaledSize: new google.maps.Size(30, 30),
            } ,
            position,
            zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
          }),
        }
      });
    }  else {
      clusterer.current.clearMarkers();
      clusterer.current.addMarkers(Object.values(markersRef.current));
    }
    
   
    
  }
  , [map,surroundedVenueList,showSurroundedVenueList]);



  const setMarkerRef = (marker, index) =>{
    if (!marker && !markersRef.current[index]) return;
    if (marker && markersRef.current[index]) return;

    if (marker) {
        markersRef.current[index] = marker;
    } else {
        delete markersRef.current[index];
    }

    if (clusterer.current) {
        clusterer.current.clearMarkers();
        clusterer.current.addMarkers(Object.values(markersRef.current));
    }
  }
  

  return(
     <>
      {
        surroundedVenueList.map((venue,index) => (
          <AdvancedMarker
            key={venue.id}
            position={{ lat: venue.location.latitude, lng: venue.location.longitude }}
            title={venue.displayName}
            onClick={() => handleMarkerClick(venue)}
            ref={(marker)=>setTimeout(() => setMarkerRef(marker,index),100)}
          >
            <img src={surPoi} width={35} height={35} />
          </AdvancedMarker>
        ))
      }
    

    {!showSurroundedVenueList && selectedVenue && !selectedVenue.isRecommended && (
      <AdvancedMarker 
        position={{ lat: selectedVenue.location.latitude, lng: selectedVenue.location.longitude }}
        title={selectedVenue.displayName}
      >
        <img src={surPoi} width={35} height={35} />
      </AdvancedMarker>
    )}
    </>
  )
}

const DraggableTable = ({navigateLocations, navigationState, selectNavigationHandler,setNavigationState,
  threeLocationHandler, setNavigateLocations}) => {
  const getLocationPos = (id) =>{
    return navigateLocations.findIndex(location => location.id === id)
  }

  const dragEndHandler = (event) =>{
    const {active, over} = event
    if(active.id === over.id){
      return
    }
    setNavigateLocations((navigateLocations) =>{
      const originalPos = getLocationPos(active.id)
      const newPos = getLocationPos(over.id)
      return arrayMove(navigateLocations, originalPos, newPos)
    })
  }

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor,{
      coordinateGetter: sortableKeyboardCoordinates
    })
  )
  return (
        <DndContext collisionDetection={closestCorners} onDragEnd={dragEndHandler} sensors={sensors}>
          <table className='dragable-table'>
            <tbody>
              <SortableContext items={navigateLocations} strategy={verticalListSortingStrategy}>
                {navigateLocations.map((navigateLocation,index) => {
                  return (
                    <DraggableRow 
                      key={navigateLocation.id} 
                      id={navigateLocation.id}
                      index={index}
                      name={navigateLocation.name} 
                      location = {navigateLocation.location?.displayName}
                      hasIcon = {navigateLocation.hasIcon}
                      navigationState={navigationState}
                      setNavigationState = {setNavigationState}
                      selectNavigationHandler = {selectNavigationHandler}
                      threeLocationHandler = {threeLocationHandler}
                    />
                  )
                })}
              </SortableContext>
            </tbody>
          </table>
        </DndContext>
  );
};

const DraggableRow = ({ id, index, navigationState, selectNavigationHandler, location,setNavigationState,
  threeLocationHandler, hasIcon }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };
  const navigateLocation = location || ""
  return (
    <>
    <tr 
    ref={setNodeRef} 
    
    style={style}>
      <td><p className='navigator-icon-marker'>{nagigateIconMarkers[index]}</p></td>
      <td className='navigator-location-draggable'>
        <div 
          className={navigationState === index ? "location  highlight" : "location"} 
          onClick={()=>selectNavigationHandler(index)}
        >
          <div className='inner-location'> {navigateLocation || navigateLabel[index]} </div>
        </div>
        <FontAwesomeIcon
          {...attributes} 
          {...listeners} 
          icon={faGripVertical} className='navigator-location-icon'
        />
      </td>
    </tr>
    {hasIcon &&   
        <FontAwesomeIcon icon={faCircleMinus} onClick={()=>{threeLocationHandler();setNavigationState(null)}} 
        className='navigator-icon three-location-icon' />}
    </>
  );
};

const Directions = ({
    navigateLocations, isThreeLocation, 
    directionsRenderer, setDirectionsRenderer, 
    isOPenNavigator, venueList,
    setVenueList}) => {
  const map = useMap();
  const routesLibrary = useMapsLibrary("routes");
  const [directionsService, setDirectionsService] = useState();

  const generateRoutesHandler = async () => {
    if (!directionsRenderer || !directionsService) {
      return;
    }

    let routeObject = {
      origin: { 
        lat: navigateLocations[0].location?.location.latitude || null, 
        lng: navigateLocations[0].location?.location.longitude || null
      }, // or a valid address string
      travelMode: google.maps.TravelMode.DRIVING,
      provideRouteAlternatives: false,
    }

    if(isThreeLocation){
      routeObject = {
        ...routeObject, 
        destination: { 
          lat: navigateLocations[2].location?.location.latitude, 
          lng: navigateLocations[2].location?.location.longitude 
        }, // or a valid address string
        waypoints: [{location: {
          lat: navigateLocations[1].location?.location.latitude, 
          lng: navigateLocations[1].location?.location.longitude 
        }, stopover: true}],
        optimizeWaypoints: false,
      }
    } else {
      routeObject = {
        ...routeObject, 
        destination: { 
          lat: navigateLocations[1].location?.location.latitude, 
          lng: navigateLocations[1].location?.location.longitude 
        }, // or a valid address string
      }
    }

    const naviSelected = navigateLocations.map(item => item.location)
    setVenueList(venueList.map(item => {
        if(!item.isRecommended) return item;
        if(naviSelected.some(location => location?._id === item._id)) return {...item, notMarker: true}
        return {...item, notMarker: false}
    }))

    try {
      const response = await directionsService.route(routeObject);
      directionsRenderer.setDirections(response);
    } catch (error) {
      console.error("Error fetching directions:", error);
    }
  };
  
  useEffect(() => {
    if (!map || !routesLibrary) return;
    if (isOPenNavigator) return;
    setDirectionsService(new routesLibrary.DirectionsService());
    setDirectionsRenderer(new routesLibrary.DirectionsRenderer({ map: map }));
  }, [map, routesLibrary, isOPenNavigator]);

  useEffect(() => {
    if(!isThreeLocation && (!navigateLocations[0].location || !navigateLocations[1].location)){
      return
    }
    if(isThreeLocation && (!navigateLocations[0].location || !navigateLocations[2].location)){
      return
    }

    if (directionsService && directionsRenderer) {
      generateRoutesHandler();
    }
  }, [directionsService, directionsRenderer, navigateLocations, isThreeLocation]);

  return null;
};


export default MapComponent;