import './RecommendationComponentStyle.css'
import { useState, useContext } from 'react';
import { venueService } from '../../service/venueService.js';
import { AuthContext } from '../../context/AuthContext';

const RecommendationForm = ({ closeForm, selectedVenue, setSelectedVenue, createRecom }) => {
    const [comment, setComment] = useState('');

    const { setLoading } = useContext(AuthContext);

    const commentLength = (str) => {
        return [...str].length;
    };

    const handleCommentChange = (e) => {
        const newComment = e.target.value;
        if (commentLength(newComment) <= 150) {
            setComment(newComment);
        }
    };

    const handleSubmit = () => {
        setLoading(true);
        selectedVenue.comment = comment;
        venueService.registerRecom(selectedVenue)
            .then((res)=>{
                if(res){
                    closeForm();
                    setSelectedVenue(res)
                    createRecom(res)
                }
            })
            .finally(() => setLoading(false));
    };

    return (
        <div className="recom-form-modal-container" data-testid="recommendation-form">
            <div className="recom-form-modal-content">
                <div className="recom-form-modal-title">Register Recommendation</div>
                <div className="recom-form-modal-venue-name">{selectedVenue.displayName}</div>
                {selectedVenue.openingHours && (
                    <div className="recom-form-modal-open-hour">
                        <div className="recom-form-modal-table-title">Open hour</div>
                        <div className="recom-form-modal-table">
                            <table>
                                <tbody>
                                    {selectedVenue.openingHours.map((hours, index) => (
                                        <tr key={index}>
                                            <td>{hours.split(': ')[0]}</td>
                                            <td>{hours.split(': ')[1]}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
                <div className={`recom-form-modal-comment-section${selectedVenue.openingHours ? '-isHours' : ''}`}>
                    <div className="recom-form-modal-comment-title">Comment</div>
                    <textarea
                        className="modal-comment"
                        rows="3"
                        cols="30"
                        maxLength={150}
                        value={comment}
                        onChange={handleCommentChange}
                        placeholder="Write a comment..."
                    ></textarea>
                    <div className="word-counter">{commentLength(comment)}/150</div>
                </div>
                <div className="recom-form-modal-buttons">
                    <button
                        className="modal-button submit-button"
                        onClick={handleSubmit}
                        disabled={comment.trim() === ''}
                    >Submit</button>
                    <button className="modal-button cancel-button" onClick={closeForm}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default RecommendationForm;