import React from "react";
import './SharedComponentStyle.css';

function Footer(){
    return(
        <footer className="footer bg-light mt-3">
            <div className="inner-footer">
                <p>
                    <span>© Netcompany</span>
                </p>
            </div>
        </footer>
    )
}


export default Footer;