import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../context/AuthContext.js';
import "./AdminUserStyle.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faMagnifyingGlass} from '@fortawesome/free-solid-svg-icons'
import Pagination from "../shared/Pagination.js"
import { adminService } from '../../service/adminService.js';
import { dateTimeFormatWithTimeZone } from '../../constant.js'
import { format } from "date-fns"
import UserModal from './UserModal.js';
import { USER_TYPE_NORMAL_USER, USER_TYPE_ADMIN } from "../../constant.js"
import swal from 'sweetalert2'

const AdminUser = () => {
  const { setLoading, authState } = useContext(AuthContext);
  const [totalPages, setTotalPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [pageRangeDisplayed, setPageRangeDisplayed] = useState(1);
  const [users, setUsers] = useState([]);
  const [isSearchLoading, setIsSearchLoading] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [isShowingUSerModal,setIsShowingUserModal] = useState(false)
  const [itemID, setItemID] = useState(null)

  useEffect(() => {
    fetchUser(currentPage, itemsPerPage, searchText);

    const handleResize = () => {
      setPageRangeDisplayed(window.innerWidth <= 700 ? 1 : 2);
    };

    handleResize(); 
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [])

  const handlePageClick = (event) => {
    fetchUser(event.selected + 1, itemsPerPage, searchText)
    setCurrentPage(event.selected + 1);
  };

  const fetchUser = async (page, perPage, search, isSearching) => {
    isSearching ? setIsSearchLoading(true) : setLoading(true)
    adminService.getUsers(page, perPage, search)
      .then((res) => { if (res) { 
        setUsers(res.users); 
        setTotalPages(res.totalPage);
      }})
      .finally(() => isSearching ? setIsSearchLoading(false) : setLoading(false));
  }

  var searchTimeout = null
  const handleSearching = (e) =>{
    clearTimeout(searchTimeout)
    searchTimeout = setTimeout(async ()=>{
      setIsSearchLoading(true)
      fetchUser(currentPage, itemsPerPage, e.target.value, true)
      setCurrentPage(1);   
      setSearchText(e.target.value)
    },300)
  }

  const openUserModalHandler = (id) =>{
    setItemID(id)
    setIsShowingUserModal(true)
  }

  const closeUSerModalHandler = () =>{
    setIsShowingUserModal(false)
  }

  const demoteUserHandler = (id) =>{
    swal.fire({
      text: "Demote this user?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#123836",
      cancelButtonColor: "#B22222",
      confirmButtonText: "Cancel",
      cancelButtonText: "Demote",
  }).then((result) => {
      if (!result.isConfirmed) {
        setLoading(true)
        adminService.demoteUser(id).then((res) => { if (res) { 
          fetchUser(currentPage, itemsPerPage, searchText);
        }})
        .finally(() => setLoading(false));
      }else{
        return
      }
    })   
  }

  const promoteUserHandler = (id) =>{
    swal.fire({
      text: "Promote this user??",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#123836",
      cancelButtonColor: "#B22222",
      confirmButtonText: "Cancel",
      cancelButtonText: "Promote",
  }).then((result) => {
      if (!result.isConfirmed) {
        setLoading(true)
        adminService.promoteUser(id).then((res) => { if (res) { 
          fetchUser(currentPage, itemsPerPage, searchText);
        }})
        .finally(() => setLoading(false));
      }else{
        return
      }
    })   
  }

  return(
      <div className='user-container'>
        {isShowingUSerModal &&
          <UserModal
            id={itemID}
            closeUserModal={closeUSerModalHandler}
          />
        }
        <div className='user-title'>User</div>
        <div className="admin-search-bar"> 
          <input type="text" placeholder="Search" className="search-bar" 
            onChange={handleSearching} maxLength={20}
          />
          {isSearchLoading ? 
            <div className='admin-search-bar-icon'>
                <div className="search-loader"></div>
            </div>
            :
            <FontAwesomeIcon icon={faMagnifyingGlass} className='admin-search-bar-icon'/>
          }
        </div>
        
        {users.length > 0 ?
          <div>
            <Pagination 
              totalPages={totalPages} 
              pageRangeDisplayed={pageRangeDisplayed} 
              handlePageClick={handlePageClick} 
              currentPage={currentPage} 
            />
            <table className='user-table'>
              <thead>
                <tr>
                  <th className='user-name-col'>Username</th>
                  <th className='user-date-col'>Created Date</th>
                  <th className='user-type-col'>Type</th>
                  <th className='user-action-col'>Action</th>
                </tr>
              </thead>
              <tbody>
                {users.map((item, index) => (
                  <tr key={index} className={index % 2 === 0 ? "hight-light-table-row":""}>
                    <td data-label="Username" className="user-name-row">
                      <b className="admin-user-name table-item" onClick={()=>{openUserModalHandler(item._id)}}>{item.userName}</b>
                    </td>
                    <td data-label="Created Date" className="user-date-row"><p className="table-item">{format(new Date(item.createdAt), dateTimeFormatWithTimeZone)}</p></td>
                    <td data-label="User Type" className="user-type-row"><p className="table-item">{item.type}</p></td>
                    <td data-label="Action" className="user-action-row">
                      <div className="button-item">
                        {item.type === USER_TYPE_ADMIN && item._id !== authState.user._id &&
                          <div className="admin-table-button-disabled admin-limit-width" onClick={()=>demoteUserHandler(item._id)}>Demote</div>
                        }
                        {item.type === USER_TYPE_ADMIN && item._id == authState.user._id &&
                          <div className="admin-table-button-unclickable admin-limit-width">Demote</div>
                        }
                        {
                          item.type === USER_TYPE_NORMAL_USER &&
                          <div className="admin-table-button-active admin-limit-width" onClick={()=>promoteUserHandler(item._id)}>Promote</div>
                        }
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination 
              totalPages={totalPages} 
              pageRangeDisplayed={pageRangeDisplayed} 
              handlePageClick={handlePageClick} 
              currentPage={currentPage} 
            />
          </div>
          :
          <p style={{"padding": "50px"}} >No result</p>
        }
        
      </div>
  )
}

export default AdminUser