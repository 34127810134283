import "./NotificationSettingModalStyle.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faXmark} from '@fortawesome/free-solid-svg-icons'
import { useContext } from "react"
import { AuthContext } from '../../context/AuthContext.js';
import { userService } from "../../service/userService";

const NotificationSettingModal = ({setIsOpenSettingModal}) =>{
    const { setLoading, authState, loadUser } = useContext(AuthContext);

    const toggleNotificationHandler = () => {
        setLoading(true);
        userService.updateEmailPref(!authState.user.emailPreference)
            .then((res) => { if (res) { 
                loadUser()
            }})
            .finally(() => setLoading(false));
    }

    const closeSettingModal = () =>{
        setIsOpenSettingModal(false)
    }

    return(
        <div className="admin-setting-modal-dim-screen">
            <div className="setting-modal">
                <p className="setting-modal-header">Setting</p>
                <FontAwesomeIcon icon={faXmark} className="setting-modal-x-button" onClick={closeSettingModal}/>
                <div className="setting-property">
                    <p>Notification</p>
                    <div className={`toggle-container ${authState.user.emailPreference ? 'container-toggled' : ''}`} onClick={toggleNotificationHandler}>
                        <div className={`toggle-slider ${authState.user.emailPreference ? 'toggled' : ''}`}></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotificationSettingModal