import apiClient from "../config/apiConfig.js";
import { BASE_API_V1 } from "../constant.js"
import { successMessage, errorMessage } from '../service/toastService.js';

class EventService {
    async getEvents() {
        try {
            const response = await apiClient.get(BASE_API_V1+'event');
            return response.data;
        } catch (error) {
            errorMessage("Error: Failed to fetch events")
            return null;
        }
    };    
    
    async getEventDetail(id) {
        try {
            const response = await apiClient.get(BASE_API_V1+'event/'+id);
            return response.data;
        } catch (error) {
            if (error.response?.data?.error === "Disabled resource"){
                errorMessage("Error: Event has been disabled by admin.");
            } else {
                errorMessage("Error: Failed to fetch event detail")
            }
            return null;
        }
    };

    async hostEvent(data){
        try {
            const res = await apiClient.post(BASE_API_V1+'image/upload', 
                {file: data.image, location: "EVENT"} , 
                {headers: 
                    {'Content-Type': 'multipart/form-data'}
                }
            );
            data.imageUrl = res.data.downloadUrl
            delete data.image

            const response = await apiClient.post(BASE_API_V1+'event',data);
            successMessage("Event created successfully.");
            return response.data
        } catch (error) {
            if (error.response?.data?.error === "Disabled resource"){
                errorMessage("Error: Recommendation has been disabled by admin.")
            } else {
                errorMessage("Error: Failed to create event. Please try again.");
            }
            return null;
        }
    }

    async register(eventId, userId) {
        try {
            const response = await apiClient.post(`${BASE_API_V1}event/${eventId}`);
            successMessage("Successfully registered for the event.");
            return response.data;
        } catch (error) {
            if (error.response?.data?.error === "Disabled resource"){
                errorMessage("Error: Event has been disabled by admin.");
            } else {
                errorMessage("Error: Failed to register for the event. Please try again.");
            }
            return null;
        }
    }

    async getEventsByHost(page, perPage, searchText) {
        try {
            const response = await apiClient.get(BASE_API_V1+'event/byHost', { 
                params: {page, perPage, searchText}
            });
            return response.data;
        } catch (error) {
            errorMessage("Error: Failed to fetch events")
            return null;
        }
    }; 

    async getEventsByParticipant(page, perPage, searchText) {
        try {
            const response = await apiClient.get(BASE_API_V1+'event/byParticipant', { 
                params: {page, perPage, searchText}
            });
            return response.data;
        } catch (error) {
            errorMessage("Error: Failed to fetch events")
            return null;
        }
    }; 

    async removeParticipant(eventId, removeUserId) {
        try {
            const response = await apiClient.post(`${BASE_API_V1}event/${eventId}/remove/${removeUserId}`);
            successMessage("Successfully remove participant from the event.");
            return response.data;
        } catch (error) {
            if (error.response?.data?.error === "Disabled resource"){
                errorMessage("Error: Event has been disabled by admin.");
            } else {
                errorMessage("Error: Failed to remove participant from the event. Please try again.");
            }
            return null;
        }
    }
}

export const eventService = new EventService();