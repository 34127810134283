import React, { useEffect, useState, useContext} from 'react';
import { AuthContext } from '../../context/AuthContext.js';
import event from '../../asset/images/event.jpg';
import './EventModalStyle.css'
import { adminService } from "../../service/adminService.js";
import { dateTimeFormat } from '../../constant.js';
import { format } from 'date-fns';

function EventModal({closeEventModal ,id}) {
  const [event, setEvent] = useState(null)
  const { setLoading } = useContext(AuthContext);

  useEffect(() => {
    fetchEventDetail()
  }, []);

  const fetchEventDetail = async () => {
    setLoading(true)
    adminService.getEventDetail(id)
      .then((res) => {
        if(res){
          setEvent(res)
        }
      })
      .finally(() => setLoading(false));
  }

  return (
    <div className='admin-event-modal-container'>
      <div className='admin-event-modal-inner-container'>
        <div className='admin-event-modal'>
          <div className='admin-event-modal-header'>
            <h1 className='admin-event-header'>{event?.name}</h1>
            <span className='admin-event-modal-close' onClick={closeEventModal}>&times;</span>
          </div>

          <div className="admin-event-img-container">
            <img className="admin-event-img" src={event?.imageUrl}></img>
          </div>

          {event ?
            <div className='admin-event-info'>
              <div className='admin-event-address'>
                <h5>Place:</h5>
                <p>{event?.place?.displayName}</p>
              </div>
              <div className='admin-event-address'>
                <h5>Address:</h5>
                <p>{event?.place?.formattedAddress}</p>
              </div>
              <div className='admin-event-hosted'>
                <h5>Hosted By:</h5>
                <p>{event?.createdBy?.userName}</p>
              </div>
              <div className='admin-event-date'>
                <h5>Date:</h5>
                <p>
                  {format(new Date(event.createdAt), dateTimeFormat)}
                  </p>
              </div>
              <div className='admin-event-start-time'>
                <h5>Start Time:</h5>
                <p>
                  {format(new Date(event.startTime), dateTimeFormat)}
                </p>
              </div>
              <div className='admin-event-end-time'>
                <h5>End Time:</h5>
                <p>
                  {format(new Date(event.endTime), dateTimeFormat)}
                </p>
              </div>
              <div className='admin-event-description'>
                <h5>Description:</h5>
                <p>{event?.description}</p>
              </div>
              <div className='admin-event-register'>
                <h5>Register:</h5>
                <p>{event?.registerList?.map(item=>item.user.userName).join(', ')}</p>
              </div>
            </div>
            :
            ""
          }
        </div>
      </div>
    </div>
  );
}

export default EventModal;