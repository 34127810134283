import apiClient from "../config/apiConfig.js";
import { BASE_API_V1 } from "../constant.js"
import { PRICE_LEVELS_ARRAY } from "../constant.js";
import dayjs from 'dayjs';
import { successMessage, errorMessage } from '../service/toastService.js';

class VenueService {
    async getVenues(position) {
        try {
            const response = await apiClient.post(BASE_API_V1+'recommendation/venues', {position: position});
            return response.data;
        } catch (error) {
            errorMessage("Error: Failed to fetch venues")
            return null;
        }
    };

    async getSurroundedVenues(position) {
        try {
            const response = await apiClient.post(BASE_API_V1 + 'recommendation/surrounded', { position: position });
            return response.data;
        } catch (error) {
            errorMessage("Error: Failed to fetch surrounded venues")
            return null;
        }
    }

    async searchAndFilterAll(position, options) {
        try {
            console.log("search all")
            const body = {
                position: position,
                ...(options.searchText !== "" && {textQuery: options.searchText}),
                filter: {
                    ...(options?.category?.value && {category: options.category.value}),
                    ...((options.maxPrice && options.minPrice) && {priceLevels: [PRICE_LEVELS_ARRAY[options.minPrice], PRICE_LEVELS_ARRAY[options.maxPrice]]}),
                    ...(options.isOpenNow && {openNow: options.isOpenNow})
                }
            }
            const response = await apiClient.post(BASE_API_V1 + 'recommendation/search', body);
            return response.data;
        } catch (error) {
            errorMessage("Error: Failed to search and filter")
            return null;
        }
    }
    // for itin search (position:get from current position medthod,{option:searchText})
    async searchAndFilterRecommendation(position, options) {
        try {
            console.log("search recom")
            let openTime = options.openTime
            if(openTime){
                openTime = dayjs(openTime).$H * 100 + dayjs(openTime).$m
            }
            
            let closeTime = options.closeTime
            if(closeTime){
                closeTime = dayjs(closeTime).$H * 100 + dayjs(closeTime).$m
            }
            const body = {
                position: position,
                ...(options.searchText !== "" && {textQuery: options.searchText}),
                filter: {
                    ...(options?.category?.value && {category: options.category.value}),
                    ...((options.maxPrice && options.minPrice) && {priceLevels: [PRICE_LEVELS_ARRAY[options.minPrice], PRICE_LEVELS_ARRAY[options.maxPrice]]}),
                    ...(options.openDay && {queryDay: options.openDay}),
                    ...(openTime && {queryOpenTime: openTime}),
                    ...(closeTime && {queryCloseTime: closeTime}),
                }
            }
            console.log(body)
            const response = await apiClient.post(BASE_API_V1 + 'recommendation/searchRecommend', body);
            return response.data;
        } catch (error) {
            errorMessage("Error: Failed to search and filter")
            return null;
        }
    }

    async registerRecom(data) {
        console.log(data)
        try {
            const response = await apiClient.post(BASE_API_V1+'recommendation', data);
            successMessage("Recommendation created successfully.");
            return response.data;
        } catch (error) {
            errorMessage("Error: Failed to create recommendation. Please try again.");
            return null;
        }
    }

    async getCurrentLocation() {
        return new Promise((resolve, reject) => {
            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(
                    position => {
                        const curPosition = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        };
                        resolve(curPosition);
                    },
                    error => reject(error)
                );
            } else {
                reject(new Error("Geolocation is not available in your browser."));
            }
        });
    }
}

export const venueService = new VenueService();