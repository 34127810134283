import { useState, useEffect, useContext } from 'react';
import "./ItineraryPageStyle.css";
import { FaSearch } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile } from '@fortawesome/free-solid-svg-icons'
import { AuthContext } from '../context/AuthContext.js';
import { itineraryService } from '../service/itineraryService.js';
import Pagination from '../component/shared/Pagination.js';
import ItineraryDetail from '../component/itineraryComponent/ItineraryDetail.js';

function ItineraryPage() {
  const { setLoading } = useContext(AuthContext);
  const [itineraryList, setItineraryList] = useState([]);
  const [searchItinerary, setSearchItinerary] = useState('');
  const [selectedItinerary, setSelectedItinerary] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [newPlanName, setNewPlanName] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const [thumbnail, setThumbnail] = useState(null);
  const [pageRangeDisplayed, setPageRangeDisplayed] = useState(2);

  const handleSearchChange = (e) => {
    setSearchItinerary(e.target.value);
    setCurrentPage(1); 
  };

  const handleThumbnailChange = (e) => {
    setThumbnail(e.target.files[0]);
  }

  const handleItineraryClick = (itinerary) => {
    setSelectedItinerary(itinerary);
  };

  const backButtonHandler = () => {
    setSelectedItinerary(null);
  };

  const filteredItineraries = itineraryList.filter((itinerary) =>
    itinerary.name.toLowerCase().includes(searchItinerary.toLowerCase())
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItineraries.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredItineraries.length / itemsPerPage);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
  };

  useEffect(() => {
    fetchItinerary();
  }, []);

  const fetchItinerary = async () => {
    setLoading(true);
    itineraryService.getItinerariess()
      .then((res) => { if (res) { setItineraryList(res); } })
      .finally(() => setLoading(false));
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setNewPlanName("");
    setThumbnail(null);
  };

  const handleNewPlanNameChange = (e) => {
    setNewPlanName(e.target.value);
  };

  const handleSavePlan = () => {
    setLoading(true);
    itineraryService.createItinerary({
        name: newPlanName,
        image: thumbnail
    }).then((res) => { 
      closeModal();
      fetchItinerary();
    }).finally(() => setLoading(false));
  };

  const isFormfilled = () => {
    return (
        newPlanName.trim() !== '' &&
        thumbnail !== null
    );
  };

  useEffect(() => {
    const handleResize = () => {
      setItemsPerPage(window.innerWidth <= 700 ? 4 : 8);
      setPageRangeDisplayed(window.innerWidth <= 700 ? 1 : 2);
    };

    handleResize(); 
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      {selectedItinerary ? (
        <div className="itinerary-details">
          <ItineraryDetail itineraryId={selectedItinerary._id} backButtonHandler={backButtonHandler}></ItineraryDetail>
        </div>
      ) : (
        <div className="itinerary-list-page-container">
          <div className="itinerary-list-box">
            <div className="itinerary-list-search-bar">
              <input
                type="text"
                placeholder="Search..."
                value={searchItinerary}
                onChange={handleSearchChange}
              />
              <FaSearch className='itinerary-list-icon' />
            </div>
            <button className='itinerary-list-addItinerarybtn' onClick={openModal}>+ Make new plan!</button>
          </div>

          {showModal && (
            <div className="itinerary-list-modal">
              <div className="itinerary-list-modal-content">
                <span className="itinerary-list-close" onClick={closeModal}>&times;</span>
                <div className='new-iti-modal-title'>New Plan</div>
                <input
                  type="text"
                  placeholder="Name"
                  value={newPlanName}
                  onChange={handleNewPlanNameChange}
                />
                <div>
                  <label>Thumbnail:</label>
                  <input 
                    type="file" 
                    className="create-iti-form-thumbnail-input" 
                    accept=".png,.jpg,.jpeg,.heic,.heif"
                    id="itinerary-thumbnail"
                    onChange={handleThumbnailChange}
                  />
                  <label htmlFor="itinerary-thumbnail" className="create-iti-form-thumbnail-button">
                    <FontAwesomeIcon icon={faFile} style={{ marginRight: '8px' }} />
                    {thumbnail ? "Selected!" : "Choose file"}
                  </label>
                </div>
                <button className="itinerary-list-save-plan-btn" onClick={handleSavePlan} disabled={!isFormfilled()}>Save plan</button>
              </div>
            </div>
          )}

          {filteredItineraries.length === 0 ? (
            <div className="no-itineraries-message">
              Start creating plan to view your future itinerary
            </div>
          ) : (
            <>
              <Pagination 
                totalPages={totalPages} 
                pageRangeDisplayed={pageRangeDisplayed} 
                handlePageClick={handlePageClick} 
                currentPage={currentPage} 
              />
              
              <div className="itinerary-list">
                {currentItems.map((itinerary) => (
                  <div key={itinerary._id} className="itinerary-item" onClick={() => handleItineraryClick(itinerary)}>
                    <img src={itinerary.imageUrl} alt="itinerary thumbnail" />
                    <div className="itinerary-info">
                      <h5 className='itinerary-item-name'>{itinerary.name}</h5>
                    </div>
                  </div>
                ))}
              </div>

              <Pagination 
                totalPages={totalPages} 
                pageRangeDisplayed={pageRangeDisplayed} 
                handlePageClick={handlePageClick} 
                currentPage={currentPage} 
              />
            </>
          )}
        </div>
      )}
    </>
  );
}

export default ItineraryPage;
