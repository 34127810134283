import React, { createContext, useState, useEffect } from 'react';
import { userService } from '../service/userService.js';
import { signInWithPopup, signOut, OAuthProvider } from "firebase/auth";
import { auth, authProvider } from '../config/firebaseConfig.js';
import Loader from '../component/shared/Loader.js'
import LoadUserLoader from '../component/shared/LoadUserLoader.js'
import Cookies from 'js-cookie';
import { successMessage, errorMessage } from '../service/toastService.js';
import axios from 'axios';


export const AuthContext = createContext();

const noAuthenticatedState = {
  isAuthenticated: false,
  user: null
}

const AuthContextProvider = ({ children }) => {

  // auth state
  const [authState, setAuth] = useState(noAuthenticatedState)
  const [loading, setLoading] = useState(false)
  const [userLoader, setUserLoader] = useState(true)

  useEffect(() => {
      loadUser().finally(() => setUserLoader(false));
  }, [])

  const microsoftLogin = async () =>{
    try {
      setLoading(true)
      const loginResult = await signInWithPopup(auth, authProvider)
      const credential = OAuthProvider.credentialFromResult(loginResult);
      const accessToken = auth.currentUser.accessToken;
      const user = await userService.afterLogin(accessToken);

      if(user == null){
        throw new Error("login fail");
      }

      if(!user.photoUrl){
        syncMicrosoftPrp(credential.accessToken).then(loadUser)
      }

      setAuth({
        isAuthenticated: true,
        user: user
      })

    }catch(error){
      console.log(error)
      errorMessage("Login failed. Please try again")
    }finally{
      setLoading(false)
    }
  }

  const syncMicrosoftPrp = async (token) => {
    const config = {
      headers: { Authorization: `Bearer ${token}`, "Content-Type": "image/jpg" },
      responseType: 'blob'
    };
    let prpRes;
    try {
      prpRes = await axios.get("https://graph.microsoft.com/v1.0/me/photo/$value", config);
      const file = new File([prpRes.data], "prp.jpg")
      await userService.uploadPrp(file);
    } catch(error){}
    
    return;
  }

  const loadUser = async () =>{
    try {
      let user = await userService.fetchUserData();
      // if(user == null){ 
      //   const idtoken = await auth.currentUser.getIdToken(/* forceRefresh */ true);
      //   user = await userService.afterLogin(idtoken);
      // }
      if(user){
        setAuth({
          isAuthenticated: true,
          user: user
        })
      } else {
        setAuth(noAuthenticatedState)
      }
    } catch (error) {
      console.log(error);
      setAuth(noAuthenticatedState)
    }
  }

  const logOut = async () =>{
    try {
      setLoading(true)
      await signOut(auth);
      setAuth(noAuthenticatedState)
      Cookies.remove('authorization');
      successMessage("Logout successful! See you again.")
      return;
    } catch (error) {
      errorMessage("Logout failed. Please try again.")
      return;
    } finally {
      setLoading(false)
    }
  }
  
  const authContextData = {
      authState, microsoftLogin, loadUser, logOut, setLoading
  };

  return (
    <>
      {userLoader ? <LoadUserLoader></LoadUserLoader> : <></>}
      {loading ? <Loader></Loader> : <></>}
      <AuthContext.Provider value={authContextData}>
          {children}
      </AuthContext.Provider>
    </>
  );
};

export default AuthContextProvider