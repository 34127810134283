import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Sidebar from '../component/adminComponent/SideBar';
import AdminActivitiLog from '../component/adminComponent/AdminActivityLog';
import AdminUser from '../component/adminComponent/AdminUser';
import AdminRecommendation from '../component/adminComponent/AdminRecommendation';
import AdminTeamEvent from '../component/adminComponent/AdminTeamEvent';
import './AdminPageStyle.css';

const AdminPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const getCurrentPage = () => {
    const params = new URLSearchParams(location.search);
    return params.get('page') || 'history';
  };

  const [currentPage, setCurrentPage] = useState(getCurrentPage());

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    if (page) {
      setCurrentPage(page);
    }
  }, [location.search]);

  const handlePageChange = (page) => {
    navigate(`?page=${page}`);
    setCurrentPage(page);
  };

  const renderContent = () => {
    switch (currentPage) {
      case 'history':
        return (
          <AdminActivitiLog/> 
        );
      case 'user':
        return (
          <AdminUser/>
        );
      case 'recommend':
        return (
          <AdminRecommendation/>
        );
      case 'team-event':
        return (
          <AdminTeamEvent/>
        );
      default:
        return null;
    }
  };

  return (
    <div className='admin-container'>
      <Sidebar currentPage={currentPage} onNavigate={handlePageChange} />
      <div className='admin-content'>
        {renderContent()}
      </div>
    </div>
  );
};

export default AdminPage;
