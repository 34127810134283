import { useState, useEffect, useContext } from 'react';
import "./TeamEventPageStyle.css";
import { FaSearch, FaCalendar } from 'react-icons/fa';
import EventDetailComponent from '../component/eventComponent/EventDetailComponent';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext.js';
import { eventService } from '../service/eventService.js';
import eventImage from '../asset/images/event.jpg'
import { dateTimeFormat } from '../constant.js'
import { format } from "date-fns"

function TeamEventPage() {
  const { setLoading } = useContext(AuthContext);
  const [eventList, setEventList] = useState([]);
  const [searchEvent, setsearchEvent] = useState('');
  const [selectedEvent, setSelectedEvent] = useState(null);

  console.log("test")

  const handleSearchChange = (e) => {
    setsearchEvent(e.target.value);
  };

  const handleEventClick = (event) => {
    setSelectedEvent(event);
  };

  const backButtonHandler = () => {
    setSelectedEvent(null);
  };

  const filteredEvents = eventList.filter((event) => {
    return event.name.toLowerCase().includes(searchEvent.toLowerCase());
  });

  useEffect(() => {
    fetchEvent();
  }, []);

  const fetchEvent = async () => {
    setLoading(true);
    eventService.getEvents()
      .then((res) => { if (res) { setEventList(res); } })
      .finally(() => setLoading(false));
  };

  return (
    <>
      {selectedEvent ? (
        <div className="event-details">
          <EventDetailComponent 
            eventId={selectedEvent._id} 
            backButtonHandler={backButtonHandler}
            fetchEvents={fetchEvent}
          ></EventDetailComponent>
        </div>
      ) : (
        <div className="team-event-list-container">
          <div className="team-event-list-box">
            <div className="team-event-list-search-bar">
              <input
                type="text"
                placeholder="Search..."
                value={searchEvent}
                onChange={handleSearchChange}
              />
              <FaSearch className='team-event-list-icon'/>
            </div>
            <NavLink to="/map"><button className='team-event-list-addEventbtn'>+ Search location for a new event</button></NavLink>
          </div>
          <div className="event-list">
            {filteredEvents.map((event) => (
              <div key={event._id} className="event-item" onClick={() => handleEventClick(event)}>
                <img src={event.imageUrl || eventImage} alt="event thumbnail" />
                <div className="event-info">
                  <span><FaCalendar className='calendar-icon'/>{format(new Date(event.startTime), dateTimeFormat)}</span>
                  <h5 className='event-item-name'>{event.name}</h5>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}

export default TeamEventPage;
