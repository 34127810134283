import apiClient from "../config/apiConfig.js";
import { BASE_API_V1 } from "../constant.js"
import { successMessage, errorMessage } from '../service/toastService.js';

class AdminService {
    async getUsers(page, perPage, searchText) {
        try {
            const response = await apiClient.get(BASE_API_V1+'admin/user', { 
                params: {page, perPage, searchText}
            });
            return response.data;
        } catch (error) {
            errorMessage("Error: Failed to fetch users")
            return null;
        }
    };

    async getUserDetail(id) {
        try {
            const response = await apiClient.get(BASE_API_V1+'admin/user/'+id);
            return response.data;
        } catch (error) {
            errorMessage("Error: Failed to fetch user detail")
            return null;
        }
    };
    
    async getLogs(page, perPage) {
        try {
            const response = await apiClient.get(BASE_API_V1+'admin/log', { 
                params: {page, perPage}
            });
            return response.data;
        } catch (error) {
            errorMessage("Error: Failed to fetch users")
            return null;
        }
    };  

    async printLog(startPage = 0, endPage = startPage) {
        try {
            const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const response = await apiClient.get(BASE_API_V1+'admin/log/print', {
                headers: {
                  'Content-Type': 'text/plain',
                },
                params: {
                    startPage,
                    endPage,
                    tz
                }
            });
            const text = await response.data;
            // Open a new window
            const printWindow = window.open('', '_blank', 'width=600,height=400');
            
            // Write the content to the new window
            printWindow.document.write('<pre>' + text + '</pre>');

            // Print the content
            printWindow.document.close();
            printWindow.focus();
            printWindow.print();

            // Close the window after printing
            printWindow.close();
        } catch (error) {
            console.log(error)
            errorMessage("Error: Failed to print activity log")
            return null;
        }
    };   

    async getRecoms(page, perPage, searchText) {
        try {
            const response = await apiClient.get(BASE_API_V1+'admin/recommendation', { 
                params: {page, perPage, searchText}
            });
            return response.data;
        } catch (error) {
            errorMessage("Error: Failed to fetch recommendations")
            return null;
        }
    };

    async getRecomDetail(id) {
        try {
            const response = await apiClient.get(BASE_API_V1+'admin/recommendation/'+id);
            return response.data;
        } catch (error) {
            errorMessage("Error: Failed to fetch recommendation detail")
            return null;
        }
    };

    async disableRecom(id){
        try {
            const response = await apiClient.post(BASE_API_V1+'admin/recommendation/'+id+"/disable");
            return response;
        } catch (error) {
            errorMessage("Error: Failed to disable recommendation")
            return null;
        }
    }

    async recoverRecom(id){
        try {
            const response = await apiClient.post(BASE_API_V1+'admin/recommendation/'+id+"/recover");
            return response;
        } catch (error) {
            errorMessage("Error: Failed to recover recommendation")
            return null;
        }
    }

    async getEvents(page, perPage, searchText) {
        try {
            const response = await apiClient.get(BASE_API_V1+'admin/event', { 
                params: {page, perPage, searchText}
            });
            return response.data;
        } catch (error) {
            errorMessage("Error: Failed to fetch team events")
            return null;
        }
    };

    async getEventDetail(id) {
        try {
            const response = await apiClient.get(BASE_API_V1+'admin/event/'+id);
            return response.data;
        } catch (error) {
            errorMessage("Error: Failed to fetch event detail")
            return null;
        }
    };

    async disableEvent(id){
        try {
            const response = await apiClient.post(BASE_API_V1+'admin/event/'+id+"/disable");
            return response;
        } catch (error) {
            errorMessage("Error: Failed to disable event")
            return null;
        }
    }

    async recoverEvent(id){
        try {
            const response = await apiClient.post(BASE_API_V1+'admin/event/'+id+"/recover");
            return response;
        } catch (error) {
            errorMessage("Error: Failed to recover event")
            return null;
        }
    }

    async demoteUser(id){
        try {
            const response = await apiClient.put(BASE_API_V1+'admin/user/'+id+"/demote");
            return response;
        } catch (error) {
            errorMessage("Error: Failed to demote user")
            return null;
        }
    }

    async promoteUser(id){
        try {
            const response = await apiClient.put(BASE_API_V1+'admin/user/'+id+"/promote");
            return response;
        } catch (error) {
            errorMessage("Error: Failed to promote user")
            return null;
        }
    }
}

export const adminService = new AdminService();