import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext.js";
import Unauthorized from "../Unauthorized.js";

const UserRoute = ({children}) => {

    const { authState } = useContext(AuthContext);

    if(authState.isAuthenticated) {
        return ( 
            children
         );
    }
    return <Unauthorized/>;
    
}
 
export default UserRoute;