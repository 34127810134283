import React, { useEffect, useState, useContext} from 'react';
import { AuthContext } from '../../context/AuthContext.js';
import profileImg from '../../asset/images/profile.png';
import { dateTimeFormat } from '../../constant.js';
import { format } from 'date-fns';
import './RecommendModalStyle.css'
import { adminService } from "../../service/adminService.js";
import { PRICE_LEVELS } from "../../constant.js";

function RecommendModal({closeRecommendModal,id}) {
  const { setLoading } = useContext(AuthContext);
  const [recom, setRecom] = useState(null)

  useEffect(() => {
    fetchRecomDetail()
  }, []);

  const fetchRecomDetail = async () => {
    setLoading(true)
    adminService.getRecomDetail(id)
      .then((res) => {
        if(res){
          setRecom(res)
        }
      })
      .finally(() => setLoading(false));
  }

  return (
    <div className='admin-recommend-modal-container'>
      <div className='admin-recommend-modal-inner-container'>
        <div className='admin-recommend-modal'>
          <div className='admin-recommend-modal-header'>
            <h1 className='admin-recommend-header'>{recom?.displayName}</h1>
            <span className='admin-recommend-modal-close' onClick={closeRecommendModal}>&times;</span>
          </div>

          <div className="admin-recommend-img-container">
            <img className="admin-recommend-img" src={recom?.photo} alt="recommendation image"></img>
          </div>

          {recom ? 
            <div className='admin-recommend-info'>
              <div className='admin-recommend-address'>
                <h5>Address:</h5>
                <p>{recom?.formattedAddress}</p>
              </div>
              <div className='admin-recommend-opening-hours'>
                <h5>Opening Hour:</h5>
                <p className="admin-opening-hour">{recom?.openingHours?.join("\n")}</p>
              </div>
              <div className='admin-recommend-recommend-by'>
                <h5>Recommend By:</h5>
                <p>{recom?.recommendedBy?.userName}</p>
              </div>
              <div className='admin-recommend-type'>
                <h5>Type:</h5>
                <p>{recom?.primaryType}</p>
              </div>
              <div className='admin-recommend-price-level'>
                <h5>Price Level:</h5>
                <p>{PRICE_LEVELS[recom.priceLevel]}</p>
              </div>
              <div className='admin-recommend-date-time'>
                <h5>Date Time:</h5>
                <p>
                  {format(new Date(recom?.createdAt), dateTimeFormat)} 
                </p>
              </div>
              <div className='admin-recommend-comment'>
                <h5>Comments:</h5>
                <div className="admin-comment-info">
                  {recom?.comments?.map((comment, index) => (
                    <div key={index} className="comment-item">
                      <div className="comment-item-top">
                        <div className="commment-item-top-user">
                          <img className="user-img" src={comment.user.photoUrl || profileImg} alt="user pfp" />
                          <div>
                            <p className="user-name">{comment.user.userName}</p>
                            <p className="comment-time">{format(new Date(comment.time), dateTimeFormat)}</p>
                          </div>
                        </div>
                      </div>
                      <div className="comment-item-bottom">
                        <p>{comment.content}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            :
            ""
          }
        </div>
      </div>
    </div>
  );
}

export default RecommendModal;