import React, { useState, useEffect, useContext, useRef } from 'react';
import MapComponent from '../component/MapComponent';
import VenueList from '../component/recommendationComponent/VenueList';
import SurroundedVenueList from '../component/recommendationComponent/SurroundedVenueList';
import UnrecomendedVenueDetail from '../component/recommendationComponent/UnrecomendedVenueDetail.js';
import RecommendedVenueDetail from '../component/recommendationComponent/RecommendedVenueDetail.js';
import RecommendationForm from '../component/recommendationComponent/RecommendationForm.js';
import HostEventForm from '../component/eventComponent/HostEventForm';
import CommentModal from '../component/recommendationComponent/CommentModal.js';
import ItineraryForm from '../component/itineraryComponent/ItineraryForm.js';
import { venueService } from '../service/venueService.js';
import { AuthContext } from '../context/AuthContext.js';
import currentLocationIcon from '../asset/images/map.png';

import mapNavigator from '../asset/images/mapNavigator.png'
import "./SharedPageStyle.css"

const defaultPosition = { lat: 0, lng: 0 };
const defaultFilterObject = {
    category: null,
    isOpenNow: false,
    openDay: null,
    openTime: null,
    closeTime: null,
    minPrice: 0,
    maxPrice: 4
}

const MapPage = () => {
    const { setLoading } = useContext(AuthContext);
    const [selectedVenue, setSelectedVenue] = useState(null);
    const [showRegisForm, setShowRegisForm] = useState(false);
    const [showHostEventForm, setShowHostEventForm] = useState(false);
    const [isOPenNavigator, setIsOpenNavigator] = useState(false)
    const [directionsRenderer, setDirectionsRenderer] = useState();
    const [showSurroundedVenueList, setShowSurroundedVenueList] = useState(false);
    const [isCLClicked, setIsCLClicked] = useState(false);
    const [position, setPosition] = useState(defaultPosition);
    const [focusPosition, setFocusPosition] = useState(null);
    const [venueList, setVenueList] = useState([]);
    const [surroundedVenueList, setSurroundedVenueList] = useState([]);
    const [isCurrentPosition, setIsCurrentPosition] = useState(false);
    const [isRecommended, setIsRecommended] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [isFiltering, setIsFiltering] = useState(false)
    const [isSearchFiltering, setIsSearchFiltering] = useState(false)
    const [filterList, setFilterList] = useState([])
    const [navigationState, setNavigationState] = useState(null)
    const [isThreeLocation, setIsThreeLocation] = useState(false)
    const [navigateLocations, setNavigateLocations] = useState([
        {id:1, location: null},
        {id:2, location: null},
        {id:3, location: null, hasIcon:true}
    ])
    const [isVenueListExtend, setIsVenueListExtend] = useState(true)
    const [showCommentModal, setShowCommentModal] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [currentComment, setCurrentComment] = useState(null);
    const isMountingRef = useRef(false);
    const [showItineraryForm, setShowItineraryForm] = useState(false);
    const [isShowingPlaceDetail, setIsShowingPlaceDetail] = useState(false)

    console.log("is show detail: "+isShowingPlaceDetail)

    useEffect(() => {
        if(JSON.parse(localStorage.getItem('filterOptionsAll')) == null){
            localStorage.setItem("filterOptionsAll", JSON.stringify(defaultFilterObject))                                                         
        }
        if(JSON.parse(localStorage.getItem('filterOptionsRecommended')) == null){
            localStorage.setItem("filterOptionsRecommended", JSON.stringify(defaultFilterObject))  
        }

        getCurrentLocation();
        setShowSurroundedVenueList(false);
        isMountingRef.current = true;
    }, []);

    useEffect(() => {
        if (isMountingRef.current) {
            fetchVenues(position);
            applyFilterHandler()
        }
    }, [position]);

    useEffect(() => {
        setSearchText(JSON.parse(localStorage.getItem(isRecommended? "searchTextRecommended":"searchTextAll")) || "")
        if (isMountingRef.current) {
            applyFilterHandler()
        }
    }, [isRecommended]);

    const toggleVenueListHandler = () =>{
        setIsVenueListExtend(!isVenueListExtend)
    }

    const openNavigatorHandler = () =>{
        if(isOPenNavigator){
            setNavigateLocations([
                {id:1, location: null},
                {id:2, location: null},
                {id:3, location: null, hasIcon:true}
            ])
            setVenueList(venueList.map(item => { return{...item, notMarker: false} }))
            directionsRenderer.setMap(null);
        }
        if(isOPenNavigator){setNavigationState(null)}
        setIsOpenNavigator(!isOPenNavigator)
    }
    
    const switchLocationHandler = () =>{
        const temp = navigateLocations[0].location
        setNavigateLocations(navigateLocations.map((item, index) => {
            if(index === 0){
                return { ...item, location: navigateLocations[1].location }
            } else if (index === 1){
                return { ...item, location: temp }
            } else return item
        }))
    }
   
    const selectNavigationHandler = (value) =>{
        if(navigationState !== null && value === navigationState){
            setNavigationState(null)
        } else {
            setNavigationState(value)
        } 
    }

    const handleSearchTextChange = (e) => {
        setSearchText(e.target.value);
        localStorage.setItem(!isRecommended ? "searchTextAll":"searchTextRecommended", JSON.stringify(e.target.value)) 
    }

    const handleVenueSelect = (venue) => {
        setFocusPosition({ lat:venue.location.latitude, lng:venue.location.longitude })
        if(navigationState === null){
            setIsShowingPlaceDetail(true)
            setSelectedVenue(venue);
        } else {
            setNavigateLocations(navigateLocations.map((item, index) => 
                index === navigationState ? { ...item, location: venue } : item
            ))
                setNavigationState(navigationState+1)
                if(isThreeLocation && navigationState === 2){
                    setNavigationState(null)
                }
                if(!isThreeLocation && navigationState === 1){
                    setNavigationState(null)
                }
            
        }
    };

    const handleCloseDetail = () => {
        setIsShowingPlaceDetail(false)
        if(document.documentElement.clientWidth > 700){
            setTimeout(() => {
                setSelectedVenue(null);
            }, 300);
        }
    };

    const handleShowRegisForm = () => setShowRegisForm(true);

    const handleCloseForm = () => setShowRegisForm(false);

    const handleShowHostEventForm = () => setShowHostEventForm(true); 

    const handleCloseHostEventForm = () => setShowHostEventForm(false);

    const handleShowItineraryForm = () => setShowItineraryForm(true);

    const handleCloseItineraryForm = () => setShowItineraryForm(false);

    const handleCreateRecommendation = (recommendation) => {
        let isExisted = false;
        let tempVenueList = venueList;
        for (let i in tempVenueList){
            if(tempVenueList[i]._id === recommendation._id){
                isExisted = true;
                tempVenueList[i] = recommendation;
                break;
            }
        }
        if(!isExisted){
            tempVenueList.push(recommendation)
        }
        setVenueList(tempVenueList);

        if(isSearchFiltering){
            setFilterList(prevList =>
                prevList.map(venue => (venue._id === recommendation._id ? recommendation : venue))
            );
        }
    };    
    
    const handleAddEditComment = (recomId, commentList) => {
        setVenueList(prevList =>
            prevList.map(venue => (venue._id === recomId ? {...venue, comments: commentList} : venue))
        );
        if(isSearchFiltering){
            setFilterList(prevList =>
                prevList.map(venue => (venue._id === recomId ? {...venue, comments: commentList} : venue))
            );
        }
    };

    const fetchVenues = async () => {
        if (position.lat !== 0 && position.lng !== 0) {
            setLoading(true);
            venueService.getVenues(position)
                .then((res) => { if (res) { setVenueList(res); } })
                .finally(() => setLoading(false));
        }
    };

    const fetchSurroundedVenues = async (curPosition) => {
        setLoading(true);
        try {
            const res = await venueService.getSurroundedVenues(curPosition);
            if (res) {
                setSurroundedVenueList(res);
            }
        } finally {
            setLoading(false);
        }
    };

    const searchAndFilterVenues = async (curPosition, options) => {
        setLoading(true);
        try {
            let res;
            if(isRecommended){
                res = await venueService.searchAndFilterRecommendation(curPosition, options);
            } else {
                res = await venueService.searchAndFilterAll(curPosition, options);
            }
            console.log(res);
            setFilterList(res||[]);
        } finally {
            setLoading(false);
        }
    }

    const checkSearchFiltering = () => {
        let options;
        const localSearchText = (JSON.parse(localStorage.getItem(isRecommended? "searchTextRecommended":"searchTextAll")) || "");
        if(isRecommended){
            options = JSON.parse(localStorage.getItem('filterOptionsRecommended'))
        } else {
            options = JSON.parse(localStorage.getItem('filterOptionsAll'))
        }

        if(options){
            console.log(options)
            const tempIsFiltering = (
                options.category !== null ||
                options.openDay !== null ||
                options.openTime !== null ||
                options.closeTime !== null ||
                options.minPrice !== 0 ||
                options.maxPrice !== 4 ||
                options.isOpenNow
            )

            if(tempIsFiltering){
                console.log("filtering")
                setIsFiltering(true)
            } else {
                setIsFiltering(false)
            }

            if(
                tempIsFiltering ||
                localSearchText !== ""
            ){
                setIsSearchFiltering(true)
                options.searchText = localSearchText;
                return options;
            }
        }
        setIsSearchFiltering(false)
        console.log("not filtering")
        return null;
    }

    const applyFilterHandler = () => {
        let options = checkSearchFiltering();
        if(options){
            searchAndFilterVenues(position, options);
        }
    }

    const getCurrentLocation = async () => {
        try {
            const curPosition = await venueService.getCurrentLocation();
            setFocusPosition(curPosition);
            setPosition(curPosition || defaultPosition);
        } catch (error) {
            console.error('Failed to fetch current location', error);
        }
    };

    const handleMoveToCurrentLocation = async () => {
        setIsCLClicked(true);
        setShowSurroundedVenueList(true);
        setIsShowingPlaceDetail(false);
        setSelectedVenue(null);
        
        try {
            const curPosition = await venueService.getCurrentLocation();
            setFocusPosition(curPosition);
            await fetchSurroundedVenues(curPosition);
        } catch (error) {
            console.error('Failed to fetch surrounded venues', error);
        }
    };

    const handleBackToVenueList = () => {
        setShowSurroundedVenueList(false);
        setIsShowingPlaceDetail(false)
        setSelectedVenue(null);
        setFocusPosition(null);
        setIsCLClicked(false);
    };

    const openCommentModal = (isEditMode) => {
        setEditMode(isEditMode);
        setShowCommentModal(true);
    }

    return (
        <div className="map-page-container">
            <div className={isVenueListExtend || showSurroundedVenueList ? "map-page-venue-list-container" : "map-page-venue-list-container map-page-venue-list-container-close"}>
                {showSurroundedVenueList ? (
                    <SurroundedVenueList
                        onSelectVenue={handleVenueSelect}
                        venueListData={surroundedVenueList}
                        isBackButtonClick={handleBackToVenueList}
                    />
                ) : (
                    <VenueList 
                        onSelectVenue={handleVenueSelect} 
                        toggleVenueListHandler={toggleVenueListHandler}
                        isVenueListExtend={isVenueListExtend} 
                        venueListData={isSearchFiltering ? filterList : venueList} 
                        isRecommended={isRecommended} 
                        setIsRecommended={setIsRecommended}
                        searchText={searchText}
                        handleSearchTextChange={handleSearchTextChange}
                        applyFilterHandler={applyFilterHandler}
                        isFiltering={isFiltering}
                    />
                )}
            </div>
                
                {(isShowingPlaceDetail && navigationState === null) && (
                    selectedVenue.isRecommended ? (
                        <div className={`map-page-venue-detail-container`} data-testid="venue-detail-container">
                            <RecommendedVenueDetail 
                                selectedVenue={selectedVenue} 
                                closeDetail={handleCloseDetail}
                                showHostEventForm={handleShowHostEventForm} 
                                showItineraryForm={handleShowItineraryForm}
                                setEditMode={setEditMode}
                                openCommentModal={openCommentModal}
                                setCurrentComment={setCurrentComment}
                            />
                        </div>
                    ) : (
                        <div className={`map-page-venue-detail-container`} data-testid="venue-detail-container">
                            <UnrecomendedVenueDetail
                                selectedVenue={selectedVenue}
                                closeDetail={handleCloseDetail}
                                showRegisForm={handleShowRegisForm}
                            />
                        </div>
                    )
                )}
            <div className="map-page-map-component-container">
                <MapComponent
                    position={position}
                    venueList={isSearchFiltering ? filterList : venueList}
                    setVenueList={isSearchFiltering ? setFilterList : setVenueList}
                    isCurrentPosition={isCurrentPosition}
                    setIsCurrentPosition={setIsCurrentPosition}
                    isCLClicked={isCLClicked}
                    setSelectedVenue={handleVenueSelect}
                    focusPosition={focusPosition}
                    setFocusPosition={setFocusPosition}
                    selectedVenue={selectedVenue}
                    selectNavigationHandler={selectNavigationHandler}
                    switchLocationHandler = {switchLocationHandler}
                    navigationState = {navigationState}
                    setNavigationState = {setNavigationState}
                    isOPenNavigator = {isOPenNavigator}
                    openNavigatorHandler = {openNavigatorHandler}
                    navigateLocations = {navigateLocations}
                    setNavigateLocations = {setNavigateLocations}
                    directionsRenderer = {directionsRenderer}
                    setDirectionsRenderer = {setDirectionsRenderer}
                    surroundedVenueList={surroundedVenueList}
                    showSurroundedVenueList={showSurroundedVenueList}
                    isThreeLocation={isThreeLocation}
                    setIsThreeLocation={setIsThreeLocation}
                />
                {!isOPenNavigator &&
                    <>
                        <img className="map-page-current-location" onClick={handleMoveToCurrentLocation} src={currentLocationIcon} alt="Current Location" />
                        <img src={mapNavigator} className='map-component-navigator-icon' alt='map navigator'onClick={openNavigatorHandler}/>
                    </>
                }
            </div>
            {showRegisForm && (
                <RecommendationForm
                    closeForm={handleCloseForm}
                    selectedVenue={selectedVenue}
                    createRecom={handleCreateRecommendation}
                    setSelectedVenue={handleVenueSelect}
                    setLoading={setLoading}
                />
            )}
            {showHostEventForm && (
                <HostEventForm
                    selectedVenue={selectedVenue}
                    closeHostEventForm={handleCloseHostEventForm}
                />
            )}
            {showCommentModal && (
                <CommentModal 
                    closeModal={() => setShowCommentModal(false)} 
                    editMode={editMode}
                    currentComment={currentComment}
                    handleAddEditComment={handleAddEditComment}
                    setSelectedVenue={setSelectedVenue}
                />
            )}
            {showItineraryForm && (
                <ItineraryForm
                    closeItineraryForm={()=> setShowItineraryForm(false)}
                    selectedVenue={selectedVenue}
                />
            )}
        </div>
    );
};

export default MapPage;
