import apiClient from "../config/apiConfig.js";
import { BASE_API_V1 } from "../constant.js"
import { successMessage, errorMessage } from './toastService.js';

class ItineraryService {
    async getItinerariess() {
        try {
            const response = await apiClient.get(BASE_API_V1+'itinerary');
            return response.data;
        } catch (error) {
            errorMessage("Error: Failed to fetch itineraries")
            return null;
        }
    };    
    
    async getItineraryDetail(id) {
        try {
            let response = await apiClient.get(BASE_API_V1+'itinerary/'+id);
            response.data.placeList = response.data.placeList.map(item => {return {...item, id: item._id}});
            return response.data;
        } catch (error) {
            errorMessage("Error: Failed to fetch itinerary detail")
            return null;
        }
    };

    async createItinerary(data){
        try {
            const res = await apiClient.post(BASE_API_V1+'image/upload', 
                {file: data.image, location: "ITINERARY"} , 
                {headers: 
                    {'Content-Type': 'multipart/form-data'}
                }
            );
            data.imageUrl = res.data.downloadUrl
            delete data.image

            const response = await apiClient.post(BASE_API_V1+'itinerary',data);
            successMessage("Itinerary created successfully.");
            return response.data
        } catch (error) {
            errorMessage("Error: Failed to create itinerary. Please try again.");
            return null;
        }
    }

    async addVenueToItinerary(id, venue){
        try {
            const itineraryRes = await apiClient.get(BASE_API_V1+'itinerary/'+id);
            const itinerary = itineraryRes.data;
            itinerary.placeList.push({_id: venue._id, isNew: true})
            let response = await apiClient.put(BASE_API_V1+'itinerary', itinerary);
            successMessage("Venue added to itinerary successfully.");
            response.data.placeList = response.data.placeList.map(item => {return {...item, id: item._id}});
            return response.data;
        } catch (error) {
            if (error.response?.data?.error === "Disabled resource"){
                errorMessage("Error: Recommendation has been disabled by admin.")
            } else {
                errorMessage("Error: Failed to add venue to itinerary. Please try again.")
            }
            return null;
        }
    }

    async updateItineraryPlaceList(itinerary, newList) {
        try {
            const itineraryDto = {
                _id: itinerary._id,
                disabledPlaceList: itinerary.disabledPlaceList || [],
                placeList: newList
            }
            let response = await apiClient.put(BASE_API_V1+'itinerary/', itineraryDto);
            response.data.placeList = response.data.placeList.map(item => {return {...item, id: item._id}});
            return response.data;
        } catch (error) {
            if (error.response?.data?.error === "Disabled resource"){
                errorMessage("Error: Recommendation has been disabled by admin.")
            } else {
                errorMessage("Error: Failed to update itinerary detail")
            }
            return null;
        }
    };
}

export const itineraryService = new ItineraryService();