import { useState, useEffect, useContext } from 'react';
import "./ItineraryFormStyle.css";
import { FaSearch } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { AuthContext } from '../../context/AuthContext.js';
import { itineraryService } from '../../service/itineraryService.js';

function ItineraryForm({ closeItineraryForm, selectedVenue }) {
  const { setLoading } = useContext(AuthContext);
  const [itineraryList, setItineraryList] = useState([]);
  const [searchItinerary, setSearchItinerary] = useState('');
  // const [selectedItinerary, setSelectedItinerary] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [newPlanName, setNewPlanName] = useState('');
  const [thumbnail, setThumbnail] = useState(null);

  const handleSearchChange = (e) => {
    setSearchItinerary(e.target.value);
  };

  const handleThumbnailChange = (e) => {
    setThumbnail(e.target.files[0]);
  }

  const handleItineraryClick = (itinerary) => {
    setLoading(true);
    itineraryService.addVenueToItinerary(itinerary._id, selectedVenue)
      .then((res) => { if (res) { closeItineraryForm(); } })
      .finally(() => setLoading(false));
  };

  const filteredItineraries = itineraryList
    .filter((itinerary) =>
      itinerary.name.toLowerCase().includes(searchItinerary.toLowerCase())
    )

  useEffect(() => {
    fetchItinerary();
  }, []);

  const fetchItinerary = async () => {
    setLoading(true);
    itineraryService.getItinerariess()
      .then((res) => { if (res) { setItineraryList(res); } })
      .finally(() => setLoading(false));
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setNewPlanName("");
    setThumbnail(null);
  };

  const handleNewPlanNameChange = (e) => {
    setNewPlanName(e.target.value);
  };

  const isFormfilled = () => {
    return (
        newPlanName.trim() !== '' &&
        thumbnail !== null
    );
  };

  const handleSavePlan = () => {
    setLoading(true);
    itineraryService.createItinerary({
      name: newPlanName,
      image: thumbnail,
      placeList: [{_id: selectedVenue._id, isNew: true}]
    }).then((res) => { if (res) {
      closeModal();
      closeItineraryForm();
    }}).finally(() => setLoading(false));
  };

  return (
    <div className="itinerary-modal-container"> 
      <div className="itinerary-modal-inner-container"> 
        <div className="itinerary-modal">
          <span className="itinerary-modal-close" onClick={closeItineraryForm}>&times;</span>
          <div className="itinerary-modal-box">
          <button className='itinerary-modal-addItinerarybtn' onClick={openModal}>+ Make new plan!</button>
          <div className='itinerary-text'>
              <p>OR</p>
              <p>Select Itinerary to add to location</p>
            </div>
            <div className="itinerary-modal-search-bar">
              <input
                type="text"
                placeholder="Search..."
                value={searchItinerary}
                onChange={handleSearchChange}
              />
              <FaSearch className='itinerary-modal-icon' />
            </div>
            
          </div>

          {showModal && (
            <div className="itinerary-list-modal">
              <div className="itinerary-list-modal-content">
                <span className="itinerary-list-close" onClick={closeModal}>&times;</span>
                <div className='new-iti-modal-title'>New Plan</div>
                <input
                  type="text"
                  placeholder="Name"
                  value={newPlanName}
                  onChange={handleNewPlanNameChange}
                />
                <div>
                  <label>Thumbnail:</label>
                  <input
                    type="file"
                    className="create-iti-form-thumbnail-input"
                    accept=".png,.jpg,.jpeg,.heic,.heif"
                    id="itinerary-thumbnail"
                    onChange={handleThumbnailChange}
                  />
                  <label htmlFor="itinerary-thumbnail" className="create-iti-form-thumbnail-button">
                    <FontAwesomeIcon icon={faFile} style={{ marginRight: '8px' }} />
                    {thumbnail ? "Selected!" : "Choose file"}
                  </label>
                </div>
                <button className="itinerary-modal-save-plan-btn" onClick={handleSavePlan} disabled={!isFormfilled()}>Save plan</button>
              </div>
            </div>
          )}

          {filteredItineraries.length === 0 ? (
            <div className="no-itineraries-message">
              Start creating a plan to view your future itinerary
            </div>
          ) : (
            <>
              <div className="itinerary-list">
                {filteredItineraries.map((itinerary) => (
                  <div key={itinerary._id} className="itinerary-item-modal" onClick={() => handleItineraryClick(itinerary)}>
                    <img src={itinerary.imageUrl} alt="itinerary thumbnail" onError={(e) => e.target.src = 'event'} />
                    <div className="itinerary-info">
                      <h5>{itinerary.name.length >= 25 ? `${itinerary.name.substring(0, 25)}...` : itinerary.name}</h5>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ItineraryForm;
