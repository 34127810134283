import { useNavigate } from 'react-router-dom';
import React, {useContext} from 'react';
import { AuthContext } from '../context/AuthContext.js';
import "./SharedPageStyle.css";
import { FaArrowRight } from 'react-icons/fa';


function LandingPage() {
  const { authState } = useContext(AuthContext);
  let isLoggedIn = authState.isAuthenticated;
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/map');
  };
  
  return (
    <div className='contain'>
      <div className="landingPage container">
        <div className="row">
          <div className="col-md-12">
            <h1>Social Suggestion</h1>
            <p>
              An application recommending user locations for meetings or team events. It allows users to register places and filter every location by category, price, and more. It helps users plan team events and calculates the shortest or most efficient path from the user’s location to the destination.
            </p>
            {isLoggedIn && (
              <button className="btn btn-primary mapButton" onClick={handleButtonClick}>
                Get me to map <FaArrowRight className='icon' />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}


export default LandingPage;
