import './FilterModalStyle.css'
import { useState,useEffect } from 'react';
import {TimePicker} from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import ReactSlider from 'react-slider'
import { TextField } from '@mui/material';
import Select, { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestion,faXmark,faCoffee,faAppleAlt,faBeer,faReceipt,faPlane,faTree,faFishFins,
    faPalette,faMoneyBills,faCakeCandles,faBuildingColumns,faHeart,faBicycle,
    faBook,faBowlingBall,faBus,faCampground,faCar,faCarSide,faScrewdriverWrench,
    faShower,faDice,faDoorClosed,faChurch,faBuildingFlag,faShirt,faShop,faPeopleRoof,
    faTooth,faStore,faUserDoctor,faCapsules,faPlugCircleBolt,faBuildingUser,faCouch,
    faFireExtinguisher,faCannabis,faCross,faGasPump,faDumbbell,faScissors,faMicrochip,
    faGopuram, faHospital,faFileCirclePlus,faGem,faJugDetergent,faGavel,faTrain,
    faMartiniGlass,faBuildingNgo,faUserLock,faBed,faTruck,faHandHoldingHand,faMosque,
    faFilm,faTicket,faPeopleCarryBox,faLandmark,faMoon,faPaintbrush,faFaceLaughBeam,
    faSquareParking,faPaw,faPrescriptionBottleMedical,faShieldHeart,faWrench,faHandcuffs,
    faEnvelopesBulk,faSchoolFlag,faSignHanging,faUtensils,faPersonShelter,faCaravan,faSchool,
    faShoePrints,faCartShopping,faSpa,faRing,faWarehouse,faTrainSubway,faSynagogue,faTaxi,
    faPersonWalkingLuggage,faTentArrowLeftRight,faRoute,faHippo,faShieldDog

 } from '@fortawesome/free-solid-svg-icons'
import swal from 'sweetalert2'
import dayjs from 'dayjs';
import typesList from '../../asset/googlePlaceCategory.json'

const { Option } = components;

const CustomOption = (props) => (
    <Option {...props}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <FontAwesomeIcon icon={props.data.icon} style={{ marginRight: 8 }} />
            {props.data.label}
        </div>
    </Option>
);
const FilterModal = ({toggleFilterModal, applyFilterHandler, isRecommended}) =>{

    const iconList = {
        faXmark:faXmark, faCoffee:faCoffee, faAppleAlt:faAppleAlt, faReceipt:faReceipt, faPlane:faPlane, faTree:faTree,
        faFishFins:faFishFins, faPalette:faPalette, faMoneyBills:faMoneyBills, faCakeCandles:faCakeCandles,
        faBuildingColumns:faBuildingColumns, faBeer:faBeer, faHeart:faHeart, faBicycle:faBicycle, faBook:faBook,
        faBowlingBall:faBowlingBall, faBus:faBus, faCampground:faCampground, faCar:faCar, faCarSide:faCarSide,
        faScrewdriverWrench:faScrewdriverWrench, faShower:faShower, faDice:faDice, faDoorClosed:faDoorClosed, faChurch:faChurch,
        faBuildingFlag:faBuildingFlag, faShirt:faShirt, faShop:faShop, faPeopleRoof:faPeopleRoof, faTooth:faTooth,
        faStore:faStore, faUserDoctor:faUserDoctor, faCapsules:faCapsules, faPlugCircleBolt:faPlugCircleBolt,
        faBuildingUser:faBuildingUser, faFireExtinguisher:faFireExtinguisher, faCannabis:faCannabis, faCross:faCross,
        faCouch:faCouch, faGasPump:faGasPump, faDumbbell:faDumbbell, faScissors:faScissors, faMicrochip:faMicrochip,
        faGopuram:faGopuram, faHospital:faHospital, faFileCirclePlus:faFileCirclePlus, faGem:faGem, faJugDetergent:faJugDetergent,
        faGavel:faGavel, faTrain:faTrain, faMartiniGlass:faMartiniGlass, faBuildingNgo:faBuildingNgo, faUserLock:faUserLock,
        faBed:faBed, faTruck:faTruck, faHandHoldingHand:faHandHoldingHand, faMosque:faMosque, faFilm:faFilm,faTicket:faTicket,
        faPeopleCarryBox:faPeopleCarryBox, faLandmark:faLandmark, faMoon:faMoon, faPaintbrush:faPaintbrush,faFaceLaughBeam:faFaceLaughBeam,
        faSquareParking:faSquareParking, faPaw:faPaw, faPrescriptionBottleMedical:faPrescriptionBottleMedical,faShieldHeart:faShieldHeart,
        faWrench:faWrench, faHandcuffs:faHandcuffs, faEnvelopesBulk:faEnvelopesBulk, faSchoolFlag:faSchoolFlag,faSignHanging:faSignHanging,
        faUtensils:faUtensils, faPersonShelter:faPersonShelter, faCaravan:faCaravan, faSchool:faSchool, faShoePrints:faShoePrints,
        faCartShopping:faCartShopping, faSpa:faSpa, faRing:faRing, faWarehouse:faWarehouse, faTrainSubway:faTrainSubway,
        faSynagogue:faSynagogue, faTaxi:faTaxi, faPersonWalkingLuggage:faPersonWalkingLuggage,faTentArrowLeftRight:faTentArrowLeftRight,
        faRoute:faRoute, faHippo:faHippo, faShieldDog:faShieldDog
    }


    const [filterOPtions,setFilterOPtions] = useState(isRecommended === false? JSON.parse(localStorage.getItem('filterOptionsAll')) : JSON.parse(localStorage.getItem('filterOptionsRecommended')))
    const [openTime, setOpenTime] = useState(filterOPtions.openTime ? filterOPtions.openTime : null) 
    const [closeTime, setCloseTime] = useState(filterOPtions.closeTime ? filterOPtions.closeTime : null)
    const [priceRange, setPriceRange] = useState([filterOPtions.minPrice,filterOPtions.maxPrice]);
    const [category, setCategory] = useState(filterOPtions.category);
    const [openDay, setOpenDay] = useState(filterOPtions.openDay);
    const [categoryJson,setCategoryJson] = useState([])
    const [reset, setReset] = useState(0)
    const [isOpenNow, setIsOpenNow] = useState(filterOPtions.isOpenNow)

    const filterObj = {
        category: category,
        openDay: openDay,
        openTime: openTime,
        closeTime: closeTime,
        minPrice: priceRange[0],
        maxPrice: priceRange[1]
    }
    const filterObjAll = {
        category: category,
        isOpenNow: isOpenNow,
        openDay: openDay,
        openTime: openTime,
        closeTime: closeTime,
        minPrice: priceRange[0],
        maxPrice: priceRange[1]
    }

    const openNowToggleHandler = () =>{
        setIsOpenNow(!isOpenNow)
    }
  
   
    const saveFilterHandler = () =>{
        if(isRecommended === false){
            localStorage.setItem("filterOptionsAll", JSON.stringify(filterObjAll))
        }else{
            localStorage.setItem("filterOptionsRecommended", JSON.stringify(filterObj))
        }
        
    }
     
    useEffect(() => {
        //convert category google name to user friendlt label
        const updatedCategories = typesList.categories.map((category) => {
            return {
                ...category,
                icon: iconList[category.icon] || faQuestion, // default icon if not found
                label: category.value
                    .split("_")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" "),
            };
        });
        setCategoryJson(updatedCategories);
    }, []);

    useEffect(()=>{
        if(category?.value === "clear_selection") setCategory(null)
        console.log("category: " + category)
    },[category])

    const resetHandler = () =>{
        setPriceRange([0,4])
        setCategory(null)
        setOpenDay(null)
        setOpenTime(null)
        setIsOpenNow(false)
        setCloseTime(null)
        setReset(reset+1)      
    } 
    const filterChangeWarningHandler = () =>{     
        var isOpenTimeAllNaN = false
        var isCloseTimeAllNaN = false
        var isOpenTimeDifferent = false
        var isCloseTimeDifferent = false
        if(isNaN(dayjs(openTime).$H) === isNaN(dayjs(filterOPtions.openTime).$H) && isNaN(dayjs(openTime).$H) && isNaN(dayjs(filterOPtions.openTime).$H)){
            isOpenTimeAllNaN = true
        }
        if(isNaN(dayjs(closeTime).$H) === isNaN(dayjs(filterOPtions.closeTime).$H) && isNaN(dayjs(closeTime).$H) && isNaN(dayjs(filterOPtions.closeTime).$H)){
            isCloseTimeAllNaN = true
        }
        if(!isOpenTimeAllNaN && (dayjs(openTime).$H !== dayjs(filterOPtions.openTime).$H || dayjs(openTime).$m !== dayjs(filterOPtions.openTime).$m)){
            isOpenTimeDifferent = true
        }
        if(!isCloseTimeAllNaN && (dayjs(closeTime).$H !== dayjs(filterOPtions.closeTime).$H || dayjs(closeTime).$m !== dayjs(filterOPtions.closeTime).$m)){
            isCloseTimeDifferent = true
        }
    
        const currentCategory = category ? category.value : null;
        const savedCategory = filterOPtions.category ? filterOPtions.category.value : null;   
        if(
            isOpenTimeDifferent||
            isCloseTimeDifferent ||
            priceRange[0] !== filterOPtions.minPrice ||
            priceRange[1] !== filterOPtions.maxPrice ||
            currentCategory !== savedCategory ||
            openDay !== filterOPtions.openDay ||
            isOpenNow !== filterOPtions.isOpenNow)
            {
                swal.fire({
                    title: "Are you sure?",
                    text: "All your current filter options will be lost!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#123836",
                    cancelButtonColor: "#B22222",
                    confirmButtonText: "Continue filtering",
                    cancelButtonText: "Close filter",
                }).then((result) => {
                    if (!result.isConfirmed) {
                        toggleFilterModal()
                    }
                });   
            }else{ 
                toggleFilterModal()
            }
        
    }
    const categoryHandler = (category) =>{
            setCategory(category)
    } 
    return(
        <div className="filter-modal-container">
            <button className='filter-x-button'>
                <FontAwesomeIcon icon={faXmark} onClick={filterChangeWarningHandler} className='filter-modal-x-icon'/>
            </button>
            <p className='filter-modal-header'>Filtering options</p>
            <table>
                <tr>
                    <td>
                        <p className='filter-modal-text'>Category</p>
                    </td>
                    <td className='filter-category-container'>
                    <Select
                    className='filter-category-select'
                        options={categoryJson} 
                        components={{ Option: CustomOption }}
                        value={category}  
                        onChange={categoryHandler}
                    />
                    </td>
                </tr>
                <tr>
                    {isRecommended ? 
                        <td
                        colSpan={2}> 
                            <p className='filter-modal-text open-day-text'>Open day</p>  
                        </td>
                        :
                        <>
                        <td> 
                            <p className='filter-modal-text open-day-text'>Open now</p>  
                        </td>
                        <td>
                            <input type='checkbox' id="checkbox" checked={isOpenNow} className='open-now-checkbox'onClick={openNowToggleHandler}/>
                            <label for="checkbox" class="custom-checkbox"></label>
                        </td>
                        </>
                    }     
                </tr>
                {isRecommended &&
                        <>
                        <tr>
                            <td colSpan={2}>
                                <div className='opening-day-checkbox'>
                                    <button onClick={()=> setOpenDay(1)} className={openDay===1?"lightup-filter-button":""}>Mon</button>
                                    <button onClick={()=> setOpenDay(2)} className={openDay===2?"lightup-filter-button":""}>Tue</button>
                                    <button onClick={()=> setOpenDay(3)} className={openDay===3?"lightup-filter-button":""}>Wed</button>
                                    <button onClick={()=> setOpenDay(4)} className={openDay===4?"lightup-filter-button":""}>Thur</button>
                                    <button onClick={()=> setOpenDay(5)} className={openDay===5?"lightup-filter-button":""}>Fri</button>
                                    <button onClick={()=> setOpenDay(6)} className={openDay===6?"lightup-filter-button":""}>Sat</button>
                                    <button onClick={()=> setOpenDay(0)} className={openDay===0?"lightup-filter-button":""}>Sun</button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p className='filter-modal-text'>Open hour</p>
                            </td>
                            <td className='filter-opening-hour'>
                                <div>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <TimePicker
                                    sx={{
                                        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: '1px solid rgba(18,56,54,0.5)', borderRadius:'15px'}, // at page load
                                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { border: '1px solid #123836'}, // at focused state
                                        '& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root': {marginTop:'0px'},      
                                        '& .css-jv54yp-MuiList-root-MuiMultiSectionDigitalClockSection-root':{background:"black",color:"black"}   
                                    }}
                                    slotProps={{
                                        popper: {
                                        sx: {
                                            "& .MuiMenuItem-root": {
                                            "&.Mui-selected": {
                                                backgroundColor: "#123836",  
                                            },
                                            "&.Mui-selected:hover": {
                                                backgroundColor: "#123836",  
                                            },
                                            },
                                        },
                                        },
                                    }}
                                    label="Time Picker"
                                    renderInput={(params) =><TextField {...params}/>}
                                    value={openTime === null ? null : dayjs(openTime)}
                                    onChange={(newValue) => setOpenTime(newValue)}
                                    />
                                </LocalizationProvider>
                                </div>
                                <p>~</p>
                                <div>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <TimePicker
                                    sx={{
                                        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: '1px solid rgba(18,56,54,0.5)', borderRadius:'15px',width:'100%'}, // at page load
                                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { border: '1px solid #123836'}, // at focused state
                                        '& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root': {marginTop:'0px'},
                                    }}
                                    slotProps={{
                                        popper: {
                                        sx: {
                                            "& .MuiMenuItem-root": {
                                            "&.Mui-selected": {
                                                backgroundColor: "#123836",  
                                            },
                                            },
                                            "& .MuiMultiSectionDigitalClockSection-root":{
                                                backgroundColor: "#red",
                                            },
                                        },
                                        },
                                    }}
                                    label="Time Picker"
                                    renderInput={(params) =><TextField {...params}/>}
                                    value={closeTime === null ? null : dayjs(closeTime)}
                                    onChange={(newValue) => setCloseTime(newValue)}/>
                                </LocalizationProvider>
                                </div>   
                            </td>
                        </tr>
                    </>
                }
                
                
                <tr>
                    <td>
                        <p className='filter-modal-text'>Price level</p>
                    </td>
                    <td>
                    <ReactSlider
                        key={reset}
                        className="horizontal-slider"
                        thumbClassName="example-thumb"
                        trackClassName="example-track"
                        renderThumb={(props, state) => <div {...props}>{state.valueNow < 3 ? "₫".repeat(state.valueNow + 1) :state.valueNow+1 +"x₫"}</div>}
                        defaultValue={priceRange}
                        max={4}
                        min={0}
                        onChange={(value, index) =>setPriceRange(value)}

                    />
                    </td>
                </tr>
            </table>
            <div className='filter-modal-button'>
                <button className='filer-aplly-button' onClick={()=>{
                        saveFilterHandler();
                        applyFilterHandler();
                        setFilterOPtions(isRecommended === false? JSON.parse(localStorage.getItem('filterOptionsAll')): JSON.parse(localStorage.getItem('filterOptionsRecommended')))
                        toggleFilterModal();
                    }}>Apply</button>
                <button className='filer-reset-button' onClick={resetHandler}>Reset</button>
            </div>
        </div>
    )
}

export default FilterModal