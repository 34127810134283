import axios from 'axios';
import { authInterceptorSuccess, authInterceptorError } from '../interceptor/authInterceptor.js';

const api = axios.create({
  baseURL: process.env.REACT_APP_AXIOS_BASE_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.response.use(authInterceptorSuccess, authInterceptorError)
export default api;