import React, { useState, useContext, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import profileImg from '../asset/images/profile.png'; 
import event from '../asset/images/event.jpg';
import './ProfilePageStyle.css';
import { FaSearch, FaEdit } from 'react-icons/fa';
import EventDetailComponent from '../component/eventComponent/EventDetailComponent.js';
import { AuthContext } from '../context/AuthContext.js';
import { dateFormat, dateTimeFormat } from '../constant.js';
import { format } from 'date-fns';
import { userService } from '../service/userService.js';
import { eventService } from '../service/eventService.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass,faGear } from '@fortawesome/free-solid-svg-icons'
import NotificationSettingModal from '../component/profileComponent/NotificationSettingModal.js';


function Profile() {
  const { setLoading, authState, loadUser } = useContext(AuthContext);

  const [totalCount, setTotalCount] = useState(1)
  const [currentPage, setCurrentPage] = useState(1);
  const [events, setEvents] = useState([]);
  const [eventsPerPage, setEventsPerPage] = useState(10);
  const [searchEvent, setSearchEvent] = useState('');
  const [isJoined, setIsJoined] = useState(false);
  const [pageRangeDisplayed, setPageRangeDisplayed] = useState(1);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isSearchLoading, setIsSearchLoading] = useState(false)
  const [isOpenSettingModal, setIsOpenSettingModal] = useState(false)

  const openSettingModalHandler = () => setIsOpenSettingModal(true)
  

  useEffect(() => {
    fetchEventsByHost();
  }, []);

  const fetchEventsByHost = async (page, perPage, search, isSearching) => {
    isSearching ? setIsSearchLoading(true) : setLoading(true)
    eventService.getEventsByHost(page||currentPage, perPage||eventsPerPage, search!==undefined ? search : searchEvent)
      .then((res) => { if (res) { 
        setEvents(res.events); 
        setTotalCount(res.totalCount);
      }})
      .finally(() => isSearching ? setIsSearchLoading(false) : setLoading(false));
  };

  const fetchEventsByParticipant = async (page, perPage, search, isSearching) => {
    isSearching ? setIsSearchLoading(true) : setLoading(true)
    eventService.getEventsByParticipant(page||currentPage, perPage||eventsPerPage, search!==undefined ? search : searchEvent)
      .then((res) => { if (res) { 
        setEvents(res.events); 
        setTotalCount(res.totalCount);
      }})
      .finally(() => isSearching ? setIsSearchLoading(false) : setLoading(false));
  };

  // const handleSearchChange = (e) => {
  //   setSearchEvent(e.target.value);
  //   setCurrentPage(1);
  // };

  var searchTimeout = null
  const handleSearchChange = (e) =>{
    clearTimeout(searchTimeout)
    searchTimeout = setTimeout(async ()=>{
      setIsSearchLoading(true)
      if(isJoined){
        fetchEventsByParticipant(1, eventsPerPage, e.target.value, true);
      } else {
        fetchEventsByHost(1, eventsPerPage, e.target.value, true);
      }
      setCurrentPage(1);   
      setSearchEvent(e.target.value)
    },300)
  }

  // const filteredEvents = events.filter((event) =>
  //   event.title.toLowerCase().includes(searchEvent.toLowerCase())
  // );
 
  const handlePageClick = (event) => {
    if(isJoined){
      fetchEventsByParticipant(event.selected + 1, eventsPerPage, searchEvent)
    } else {
      fetchEventsByHost(event.selected + 1, eventsPerPage, searchEvent)
    }
    setCurrentPage(event.selected + 1);
  };

  const isHostedHandler = () => {
    fetchEventsByHost(1, eventsPerPage, searchEvent)
    setIsJoined(false);
    setCurrentPage(1);
  };

  const isJoinedHandler = () => {
    fetchEventsByParticipant(1, eventsPerPage, searchEvent)
    setIsJoined(true);
    setCurrentPage(1); 
  };

  const handleEventClick = (event) => {
    setSelectedEvent(event);
  };

  const backButtonHandler = () => {
    setSelectedEvent(null);
  }

  const handlePrpChange = async (e) => {
    setLoading(true)
    userService.uploadPrp(e.target.files[0], true)
      .then(async (res) => { 
        if (res) { 
          await loadUser()
        } 
      }).finally(() => setLoading(false));
}

  return (
    <div className="profile-page">
      {selectedEvent ? (
        <EventDetailComponent 
          className="event-detail"
          eventId={selectedEvent._id} 
          backButtonHandler={backButtonHandler} 
          fetchEvents={isJoined ? fetchEventsByParticipant : fetchEventsByHost}/>
      ) : (
        <div className="profile-container">
          <h1 className='profile-mobile-header'>Profile</h1>
          <div className="profile-header">
            
            <div className="profile-image-container">
              <img src={authState.user.photoUrl || profileImg} alt="Profile" className="profile-img" />
              <input 
                type="file" 
                accept=".png,.jpg,.jpeg,.heic,.heif"
                id="prp"
                onChange={handlePrpChange}
              />
              <label htmlFor="prp" className='edit-profile-button'>
                <FaEdit className='edit-icon'/>
              </label>
            </div>
            <div className="profile-info">
              <h1>{authState.user.userName}</h1>
              <p>Registered on </p>
              <p>{format(new Date(authState.user.createdAt), dateFormat)}</p>
              <div className='profile-setting-button' onClick={openSettingModalHandler}>
                <FontAwesomeIcon icon={faGear} className='setting-icon' />
                <p>Setting</p> 
              </div>
            </div>
          </div>

          <div className="events-section">
            <div className="profile-events-top">
              <h1>Profile</h1>
              <div className="search-bar">
                <input
                  type="text"
                  placeholder="Search..."
                  onChange={handleSearchChange}
                />
                {isSearchLoading ? 
                    <div className='icon'>
                        <div className="itinerary-search-loader"></div>
                    </div>
                  :
                    <FontAwesomeIcon icon={faMagnifyingGlass} className='icon'/>
                }
              </div>
            </div>
            <div className="profile-events-middle">
              <div className="profile-events-middle-top">
                <div className='total'>
                  <p>
                    Total {totalCount}{' '}
                    {isJoined ? 'joined events' : 'hosted events'}
                  </p>
                </div>
                <div className='filtered'>
                  <table className='events-tabs'>
                    <tbody>
                      <tr>
                        <td
                          data-testid="hosted"
                          onClick={isHostedHandler}
                          className={!isJoined ? 'netcompany-green-color' : ''}
                        >
                          HOSTED
                        </td>
                        <td
                          data-testid="joined"
                          onClick={isJoinedHandler}
                          className={isJoined ? 'netcompany-green-color' : ''}
                        >
                          JOINED
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className='total-mobile'>
                <p>
                  Total {totalCount}{' '}
                  {isJoined ? 'joined events' : 'hosted events'}
                </p>
              </div>
              <div className='paginate'>
                <ReactPaginate
                  previousLabel={'<'}
                  nextLabel={'>'}
                  breakLabel={'...'}
                  pageCount={Math.ceil(totalCount/eventsPerPage)}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={pageRangeDisplayed}
                  onPageChange={handlePageClick}
                  containerClassName={'pagination'}
                  pageClassName={'page-item'}
                  pageLinkClassName={'page-link'}
                  previousClassName={'page-item previous'}
                  previousLinkClassName={'page-link'}
                  nextClassName={'page-item next'}
                  nextLinkClassName={'page-link'}
                  breakClassName={'page-item'}
                  breakLinkClassName={'page-link'}
                  activeClassName={'active'}
                  forcePage={currentPage - 1}
                />
              </div>
            </div>
            <div className='event-list-container'>
              <div className="event-list">
                {events.map((event, index) => (
                  <div key={index} className="event-items" onClick={() => handleEventClick(event)}>
                    <img
                      src={event.imageUrl}
                      alt={event.name}
                      className="event-image"
                    />
                    <div className="event-details">
                      <p>{format(new Date(event.startTime), dateTimeFormat)}</p>
                      <h3>{event.name}</h3>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
      {isOpenSettingModal && 
        <NotificationSettingModal 
          setIsOpenSettingModal={setIsOpenSettingModal}
        />
      }
    </div>
  );
}

export default Profile;
