import apiClient from "../config/apiConfig.js";
import { BASE_API_V1 } from "../constant.js"
import { successMessage, errorMessage } from '../service/toastService.js';

class UserService {
    async fetchUserData() {
        try {
            const response = await apiClient.get(BASE_API_V1+'user');
            return response.data;
        } catch (error) {
            // console.error('Error fetching user data:', error);
            return null;
        }
    };

    async afterLogin(token) {
        try {
            let config = {
                headers: {
                    authorization: "Bearer "+token
                }
            }
            const response = await apiClient.get(BASE_API_V1+'user/login', config);
            return response.data;
        } catch (error) {
            // console.error('Error fetching user data:', error);
            return null;
        }
    };

    async uploadPrp(image, isToast) {
        try {
            const res = await apiClient.post(BASE_API_V1+'image/upload', 
                {file: image, location: "USER"} , 
                {headers: 
                    {'Content-Type': 'multipart/form-data'}
                }
            );

            const response = await apiClient.put(BASE_API_V1+'user/prp', {photoUrl: res.data.downloadUrl});
            if(isToast){successMessage("Profile picture uploaded successfully.")}
            return response.data
        } catch (error) {
            if(isToast){errorMessage("Error: Failed to upload profile picture. Please try again.")}
            return null;
        }
    }

    async updateEmailPref(pref){
        try {
            const response = await apiClient.put(BASE_API_V1+'user/email-preference', {emailPreference: pref});
            successMessage("Email preference updated successfully.")
            return response.data
        } catch (error) {
            errorMessage("Error: Failed to update email preference. Please try again.")
            return null;
        }
    }
}

export const userService = new UserService();