import "./AdminTeamEventStyle.css"
import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../context/AuthContext.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faMagnifyingGlass} from '@fortawesome/free-solid-svg-icons'
import Pagination from "../shared/Pagination.js"
import { adminService } from '../../service/adminService.js';
import { dateTimeFormatWithTimeZone } from '../../constant.js'
import { format } from "date-fns"
import EventModal from "./EventModal.js";
import swal from 'sweetalert2'


const AdminTeamEvent = () =>{
  const { setLoading } = useContext(AuthContext);
  const [totalPages, setTotalPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [pageRangeDisplayed, setPageRangeDisplayed] = useState(1);
  const [events, setEvents] = useState([]);
  const [isSearchLoading, setIsSearchLoading] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [isShowingEventModal,setIsShowingEventModal] = useState(false)
  const [itemID, setItemID] = useState(null)

  useEffect(() => {
    fetchEvent(currentPage, itemsPerPage, searchText);

    const handleResize = () => {
      setPageRangeDisplayed(window.innerWidth <= 700 ? 1 : 2);
    };
    handleResize(); 
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [])

  const handlePageClick = (event) => {
    fetchEvent(event.selected + 1, itemsPerPage, searchText)
    setCurrentPage(event.selected + 1);
  };

  const fetchEvent = async (page, perPage, search, isSearching) => {
    isSearching ? setIsSearchLoading(true) : setLoading(true)
    adminService.getEvents(page, perPage, search)
      .then((res) => { if (res) { 
        setEvents(res.events); 
        setTotalPages(res.totalPage);
      }})
      .finally(() => isSearching ? setIsSearchLoading(false) : setLoading(false));
  }

  

  var searchTimeout = null
  const handleSearching = (e) =>{
    clearTimeout(searchTimeout)
    searchTimeout = setTimeout(async ()=>{
      setIsSearchLoading(true)
      fetchEvent(currentPage, itemsPerPage, e.target.value, true)
      setCurrentPage(1);   
      setSearchText(e.target.value)
    },300)
  }

  const disableEventHandler = (id) =>{
    swal.fire({
      text: "Disable this event?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#123836",
      cancelButtonColor: "#B22222",
      confirmButtonText: "Cancel",
      cancelButtonText: "Disable",
  }).then((result) => {
      if (!result.isConfirmed) {
        setLoading(true)
        adminService.disableEvent(id).then((res) => { if (res) { 
        fetchEvent(currentPage, itemsPerPage, searchText);
    }})
    .finally(() => setLoading(false));
      }else{
        return
      }
    })   
  }

  const recoverEventHandler = (id) =>{
    swal.fire({
      text: "Disable this event?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#123836",
      cancelButtonColor: "#B22222",
      confirmButtonText: "Cancel",
      cancelButtonText: "Recover",
  }).then((result) => {
      if (!result.isConfirmed) {
        setLoading(true)
        adminService.recoverEvent(id).then((res) => { if (res) { 
          fetchEvent(currentPage, itemsPerPage, searchText);
      }})
      .finally(() => setLoading(false));
        }else{
          return
        }
    })  
  }

  const openEventModalHandler = (id) =>{
    setItemID(id)
    setIsShowingEventModal(true)
  }
  const closeEventModalHandler = () =>{
    setIsShowingEventModal(false)
  }
  return(
      <div className='teamevent-container'>
        {isShowingEventModal && 
            <EventModal
            closeEventModal={closeEventModalHandler} 
            id={itemID}
            />
        }
        <div className='teamevent-title'>Team Events</div>
        <div className="admin-search-bar"> 
          <input type="text" placeholder="Search" className="search-bar" 
            onChange={handleSearching} maxLength={20}
          />
          {isSearchLoading ? 
            <div className='admin-search-bar-icon'>
                <div className="search-loader"></div>
            </div>
            :
            <FontAwesomeIcon icon={faMagnifyingGlass} className='admin-search-bar-icon'/>
          }          
        </div>
        {events.length > 0 ?
          <div>
            <Pagination 
              totalPages={totalPages} 
              pageRangeDisplayed={pageRangeDisplayed} 
              handlePageClick={handlePageClick} 
              currentPage={currentPage} 
            />
            <table className='teamevent-table'>
              <thead>
                <tr>
                  <th className="event-name-col">Name</th>
                  <th className="event-status-col">Status</th>
                  <th className="event-date-col">Created Date</th>
                  <th className="event-action-col">Action</th>
                </tr>
              </thead>
              <tbody>
                {events.map((item, index) => (
                  <tr key={index} className={index % 2 === 0 ? "hight-light-table-row" : ""}>
                    <td data-label="Name" className="event-name-row">
                      <b className="admin-event-name table-item" onClick={() => { openEventModalHandler(item._id) }}>
                        {item.name}
                      </b>
                    </td>
                    <td data-label="Status" className={!item.isDisabled ? "admin-action-active" : "admin-action-disable"}>
                      <p style={{ "marginBottom": "0" }} className="admin-limit-width table-item">
                        {item.isDisabled ? "Disabled" : "Active"}
                      </p>
                    </td>
                    <td data-label="Created Date">
                    <span className="table-item">
                      {format(new Date(item.createdAt), dateTimeFormatWithTimeZone)}
                    </span>
                    </td>
                    <td data-label="Action">
                      {!item.isDisabled ?
                        <div className="table-item">
                          <div className="admin-table-button-disabled admin-limit-width" onClick={() => disableEventHandler(item._id)}>Disable</div>
                        </div>
                        :
                        <div className="table-item">
                          <div className="admin-table-button-active admin-limit-width" onClick={() => recoverEventHandler(item._id)}>Recover</div>
                        </div>
                      }
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination 
              totalPages={totalPages} 
              pageRangeDisplayed={pageRangeDisplayed} 
              handlePageClick={handlePageClick} 
              currentPage={currentPage} 
            />
          </div>
          :
          <p style={{"padding": "50px"}} >No result</p>
        }
      </div>
  )
}

export default AdminTeamEvent