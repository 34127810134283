import apiClient from "../config/apiConfig.js";
import { BASE_API_V1 } from "../constant.js"
import { successMessage, errorMessage } from '../service/toastService.js';

class CommentService {
    async addComment(recomId, comment) {
        try {
            const response = await apiClient.post(BASE_API_V1+'recommendation/'+recomId+'/comment', {content: comment.content});
            successMessage("Comment added successfully.");
            return response.data;
        } catch (error) {
            if (error.response?.data?.error === "Disabled resource"){
                errorMessage("Error: Recommendation has been disabled by admin.");
            } else {
                errorMessage("Error: Failed to add comment");
            }
            return null;
        }
    };    
    
    async editComment(recomId, comment) {
        try {
            const response = await apiClient.patch(
                BASE_API_V1+'recommendation/'+recomId+'/comment/'+comment._id, 
                {content: comment.content}
            );
            successMessage("Comment updated successfully.");
            return response.data;
        } catch (error) {
            if (error.response?.data?.error === "Disabled resource"){
                errorMessage("Error: Recommendation has been disabled by admin.");
            } else {
                errorMessage("Error: Failed to update comment");
            }
            return null;
        }
    };
}

export const commentService = new CommentService();