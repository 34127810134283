import api from "../config/apiConfig.js";
import { auth } from "../config/firebaseConfig.js";
import { userService } from "../service/userService.js";

export const authInterceptorSuccess = (response) => {
    return response;
}

export const authInterceptorError = async (error) => {
    const originalReq = error.config;
    if(error.response.status === 403 && !originalReq._retry) {
        originalReq._retry = true;
        const newIdToken = await auth.currentUser.getIdToken(true);
        await userService.afterLogin(newIdToken);
        return api(originalReq);
    }
    return Promise.reject(error);
}