import './CommentModalStyle.css';
import { useState, useEffect, useContext } from 'react';
import { successMessage, errorMessage } from '../../service/toastService.js';
import { commentService } from '../../service/commentService.js';
import { AuthContext } from '../../context/AuthContext';

const CommentModal = ({ closeModal, editMode, currentComment, handleAddEditComment, setSelectedVenue }) => {
    const { setLoading } = useContext(AuthContext);

    const [comment, setComment] = useState('');

    const commentLength = (str) => {
        return [...str].length;
    };

    const handleCommentChange = (e) => {
        const newComment = e.target.value;
        if (commentLength(newComment) <= 150) {
            setComment(newComment);
        }
    };

    const handleSubmit = () => {
        setLoading(true);
        if(editMode){
            commentService.editComment(currentComment.recomId, {_id: currentComment.comment._id, content: comment})
            .then((res)=>{
                if(res){
                    handleAddEditComment(currentComment.recomId, res.comments)
                    setSelectedVenue(old => {return{...old, comments: res.comments}})
                    closeModal();
                }
            })
            .finally(() => setLoading(false));
        }else{
            commentService.addComment(currentComment.recomId, {content: comment})
            .then((res)=>{
                if(res){
                    handleAddEditComment(currentComment.recomId, res)
                    setSelectedVenue(old => {return{...old, comments: res}})
                    closeModal();
                }
            })
            .finally(() => setLoading(false));
        }
    };

    useEffect(() => {
        if(editMode) {
            setComment(currentComment.comment.content);
        }
    }, []);

    return (
        <div className="comment-modal-container">
            <div className="comment-modal-content">
                <div className="comment-modal-title">{editMode ? "Edit comment" : "Add comment"}</div>
                <div className="comment-modal-comment-section">
                    <textarea
                        className="modal-comment"
                        rows="3"
                        cols="30"
                        maxLength={150}
                        value={comment}
                        onChange={handleCommentChange}
                        placeholder="Write a comment..."
                    ></textarea>
                    <div className="word-counter">{commentLength(comment)}/150</div>
                </div>
                <div className="comment-modal-buttons">
                    <button
                        className="modal-button submit-button"
                        onClick={handleSubmit}
                        disabled={comment.trim() === ''}
                    >Submit</button>
                    <button className="modal-button cancel-button" onClick={closeModal}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default CommentModal;
