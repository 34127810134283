import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const style = {
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
  
  }

  const successMessage = (message) =>{
    toast.success(message, style);
  }

  const errorMessage = (message) =>{
    toast.error(message, style)
  }

  const infoMessage = (message) =>{
    toast.info(message, style);
  }

  const warningMessage = (message) =>{
    toast.warn(message, style);
  }


export {successMessage,errorMessage,infoMessage,warningMessage}