import ReactPaginate from 'react-paginate';
import './PaginationStyle.css'

export default function Pagination({ totalPages, pageRangeDisplayed, handlePageClick, currentPage }){
    return(
        <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            pageCount={totalPages}
            marginPagesDisplayed={1}
            pageRangeDisplayed={pageRangeDisplayed}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            previousClassName={'page-item previous'}
            previousLinkClassName={'page-link'}
            nextClassName={'page-item next'}
            nextLinkClassName={'page-link'}
            breakClassName={'page-item'}
            breakLinkClassName={'page-link'}
            activeClassName={'active'}
            forcePage={currentPage - 1}
        />
    )
}