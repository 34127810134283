import './ItineraryDetailStyle.css'
import { APIProvider, Map, AdvancedMarker, useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faChevronLeft, faGripVertical, faTrashCan,faMagnifyingGlass} from '@fortawesome/free-solid-svg-icons'
import itinPoi from '../../asset/images/itinPoi.png';
import { useState, useContext, useEffect, useRef, useCallback } from 'react';
import {DndContext, KeyboardSensor, PointerSensor, TouchSensor, closestCorners, useSensor, useSensors} from "@dnd-kit/core"
import {SortableContext, verticalListSortingStrategy,useSortable, arrayMove, sortableKeyboardCoordinates} from '@dnd-kit/sortable'
import {CSS} from '@dnd-kit/utilities'
import swal from 'sweetalert2'
import ItinerarySearchVenueList from './ItinerarySearchVenueList';
import ItinerarySearchVenueDetail from './ItinerarySearchVenueDetail';
import { venueService } from '../../service/venueService';
import netPoi from '../../asset/images/netPoi.png';
import { AuthContext } from '../../context/AuthContext';
import { itineraryService } from '../../service/itineraryService';
import { errorMessage } from '../../service/toastService';
import { MarkerClusterer } from '@googlemaps/markerclusterer';

const defaultPosition = { lat: 0, lng: 0 };

/*global google*/

const ItineraryDetail = ({itineraryId, backButtonHandler}) =>{
    const { setLoading } = useContext(AuthContext);
    const [position, setPosition] = useState(defaultPosition)
    const [zoom, setZoom] = useState(15);
    const [focusPosition, setFocusPosition] = useState(null);
    const [selectedVenue, setSelectedVenue] = useState(null);
    const [directionsRenderer, setDirectionsRenderer] = useState();
    const [route, setRoute] = useState(null);
    const [routeClickCount, setRouteClickCount] = useState(0);
    const [isOptimising, setIsOptimising] = useState(false)
    const [searchResultList, setSearchResultList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isShowingPlaceDetail, setIsShowingPlaceDetail] = useState(false)
    const [locationList, setLocationList] = useState([])
    const [itinerary, setItinerary] = useState({})
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);

    const searchInputRef = useRef(null);
    const itineraryNameRef = useRef(null);

    useEffect(() => {
        getCurrentLocation();
        fetchItineraryDetail()
    }, []);

    useEffect(() => {
        if (selectedVenue) {
            setIsShowingPlaceDetail(true);
        }
    }, [selectedVenue]);

    const fetchItineraryDetail = async () => {
        setLoading(true)
        itineraryService.getItineraryDetail(itineraryId)
            .then((res) => {
                if(res){
                    setItinerary(res);
                    setLocationList(res.placeList)
                }
            })
            .finally(() => setLoading(false));
        
    }

    const getCurrentLocation = async () => {
        try {
            const curPosition = await venueService.getCurrentLocation();
            setFocusPosition(curPosition);
            setPosition(curPosition || defaultPosition);
        } catch (error) {
            console.error('Failed to fetch current location', error);
        }
    };

    var searchTimeout = null
    const searchTextChangeHandler = (e) =>{
        clearTimeout(searchTimeout)
        if(e.target.value !== ""){
            searchTimeout = setTimeout(async ()=>{
                setIsLoading(true)
                venueService.searchAndFilterRecommendation(position,{searchText:e.target.value})
                    .then((res) => { if (res) { setSearchResultList(res); } })
                    .finally(() => setIsLoading(false));
            },300)
        }else {
            setSearchResultList([])
        }
    }

    const venueSelectHandler = (venue) =>{
        setSelectedVenue(venue)
        setFocusPosition({ lat:venue.location.latitude, lng:venue.location.longitude })
        setZoom(15)
    }

    const deleteLocationHandler = (locationItem) =>{
        swal.fire({
            title: "Remove this location?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#B22222",
            cancelButtonColor: "#123836",
            confirmButtonText: "Remove",
            cancelButtonText: "Cancel",
        }).then(async (result) => {
            if (result.isConfirmed) {
                setIsOptimising(false)
                if(locationItem){
                    setLoading(true)
                    let newList = locationList.filter((location)=>{
                        location = {_id: location._id}
                        return location._id !== locationItem._id
                    })
                    itineraryService.updateItineraryPlaceList(itinerary, newList)
                        .then((res) => {if(res){setLocationList(res.placeList)}})
                        .finally(() => setLoading(false))
                }
            }
        });
        
    }

    const optimizeRouteHandler = () =>{
        swal.fire({
            title: "Optimize route",
            text: "Optimisation will reorder your list and this can not be undo",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#B22222",
            cancelButtonColor: "#123836",
            confirmButtonText: "Optimize",
            cancelButtonText: "Cancel",
        }).then((result) => {
            if (result.isConfirmed) {
                setRoute("optimize")
                setRouteClickCount(old => old+1)
                console.log("route has been optimzed")
            }
        });
    }

    const generateRouteHandler = () => {
        setRoute("normal")
        setRouteClickCount(old => old+1)
    }

    const addToListHandler = () =>{
        if(locationList.length >= 10){
            errorMessage("Humber of location can not exceed 10")
            return
        }
        setIsOptimising(false)
        setLoading(true)
        let newList = locationList.map((location)=> location = {_id: location._id})
        newList.push({_id: selectedVenue._id, isNew: true})
        itineraryService.updateItineraryPlaceList(itinerary, newList)
            .then((res) => {if(res){setLocationList(res.placeList)}})
            .finally(() => setLoading(false))
        setIsShowingPlaceDetail(false)
        if(document.documentElement.clientWidth > 700){
            setSelectedVenue(null);
        }
        if (searchInputRef.current) {
            searchInputRef.current.value = "";
        }
        setSearchResultList([]);

    }

    const closePlaceDetailHandler = () => {
        setIsShowingPlaceDetail(false)
        if(document.documentElement.clientWidth > 700){
            setSelectedVenue(null);
        }
    }

    const adjustFontSize = (element) => {
        if (!element) return;
    
        const lineHeight = parseInt(window.getComputedStyle(element).lineHeight, 10);
        const height = element.clientHeight;
        const numberOfLines = height / lineHeight;
    
        // Add class for smaller font if text exceeds 3 lines
        if (numberOfLines > 2) {
            element.classList.add('small-font');
        } else {
            element.classList.remove('small-font');
        }
    };
    const checkOverflow = (element) => {
        if (!element) return;
        setIsTooltipVisible(element.scrollWidth > element.clientWidth);
    }
    const locationNameRef = useRef(null);

    useEffect(() => {
        adjustFontSize(locationNameRef.current);
        checkOverflow(itineraryNameRef.current);
        window.addEventListener('resize', () => {
            adjustFontSize(locationNameRef.current);
            checkOverflow(itineraryNameRef.current);
        });
        return () => window.removeEventListener('resize', () => {
            adjustFontSize(locationNameRef.current);
            checkOverflow(itineraryNameRef.current);
        });
    }, [itinerary?.name]);

    return(
        <div className='itinerary-detail-container'>
            <div className='itinerary-detail-info'>
                <div className='itinerary-header'>
                    <div className='itinerary-detail-back-button back-btn-desktop' onClick={backButtonHandler}><FontAwesomeIcon icon={faChevronLeft} />Back</div>
                    <div className='itinerary-detail-name-and-tool-tip'>
                        <div className='itinerary-detail-name' ref={itineraryNameRef}>
                            {itinerary?.name}
                        </div>
                        {isTooltipVisible && <span className='tooltip-text'>{itinerary?.name}</span>}
                    </div>
                </div>
                <div className='location-list-header location-list-header-desktop'><b>Location list ({locationList.length}/10)</b></div>
                <div className='itinerary-location-list'>
                    <div className='itinerary-inner-list'>
                        <DraggableTable
                            locationList = {locationList}
                            setLocationList = {setLocationList}
                            deleteLocationHandler = {deleteLocationHandler}
                            venueSelectHandler = {venueSelectHandler}
                            setIsOptimising = {setIsOptimising}
                            itinerary = {itinerary}
                            locationNameRef = {locationNameRef}
                        />
                    </div>
                </div>
                <div className='itinerary-detail-buttons'>
                    {locationList.length >= 2 ? 
                        <>
                            {!route ?
                                <>
                                    <div className='itinerary-detail-button' onClick={generateRouteHandler}>Generate route</div>
                                    <div className='itinerary-detail-button route-button-mobile' onClick={generateRouteHandler}>Route</div>
                                </>
                                :
                                <>
                                    <div className='itinerary-detail-button disabled' onClick={generateRouteHandler}>Generate route</div>
                                    <div className='itinerary-detail-button route-button-mobile disabled' onClick={generateRouteHandler}>Route</div>
                                </>
                            }
                        </>  
                        :
                        <>
                            <div className='itinerary-detail-button disabled'>Generate route</div>
                            <div className='itinerary-detail-button route-button-mobile disabled'>Route</div>
                        </>  
                    }
                    
                    {locationList.length >= 4 ? 
                        <>
                            <div className='itinerary-detail-button'onClick={optimizeRouteHandler}>Generate optimized route</div>
                            <div className='itinerary-detail-button route-button-mobile'onClick={optimizeRouteHandler}>Optimized route</div>
                        </>
                        :
                        <>
                            <div className='itinerary-detail-button disabled'>Generate optimized route</div>
                            <div className='itinerary-detail-button route-button-mobile disabled'>Optimized route</div>
                        </>
                    }
                </div>
            </div>
            {isShowingPlaceDetail &&
                <div className='venue-search-detail-container search-venue-detail-mobile'>
                    <ItinerarySearchVenueDetail
                        selectedVenue={selectedVenue}
                        setSelectedVenue={setSelectedVenue} 
                        locationList = {locationList}
                        addToListHandler = {addToListHandler}
                        closePlaceDetailHandler = {closePlaceDetailHandler}
                    />
                </div>
            }
            <div className='itinerary-detail-map'>
                <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY} libraries={[]} >
                    <Map
                        defaultZoom={15}
                        zoom={zoom}
                        defaultCenter={position}
                        center={focusPosition}
                        disableDefaultUI
                        mapId={process.env.REACT_APP_MAP_ID}
                        onDrag={()=>{ setFocusPosition(null) }}
                        onZoomChanged={() => { setZoom(null) }}
                        style={{width: '100%', height: '100%'}}
                    >
                        <Marker
                             selectedVenue = {selectedVenue}
                             locationList = {locationList}
                             setSelectedVenue = {setSelectedVenue}
                         />
                        {route && 
                            <Directions
                                locationList = {locationList}
                                directionsRenderer = {directionsRenderer}
                                itinerary = {itinerary}
                                setDirectionsRenderer = {setDirectionsRenderer}
                                route = {route}
                                setLocationList = {setLocationList}
                                isOptimising = {isOptimising}
                                setIsOptimising = {setIsOptimising}
                                setRoute = {setRoute}
                                routeClickCount = {routeClickCount}
                            />
                        }
                    </Map>
                </APIProvider>
                <div className='itinerary-map-search-bar'>
                    <div className='itinerary-detail-back-button back-btn-mobile' onClick={backButtonHandler}>
                        <FontAwesomeIcon icon={faChevronLeft} />Back
                    </div>
                    <input 
                        type='text' 
                        placeholder='Search'
                        className='itinerary-map-search-bar-input' 
                        ref={searchInputRef} 
                        onChange={(e)=>searchTextChangeHandler(e)}
                        maxLength={20}
                    />
                    {isLoading ? 
                        <div className='itinerary-search-bar-icon'>
                            <div className="itinerary-search-loader"></div>
                        </div>
                    :
                        <FontAwesomeIcon icon={faMagnifyingGlass} className='itinerary-search-bar-icon'/>
                    } 
                    {searchResultList.length > 0 &&
                    <div className='venue-search-list-container'>
                        <ItinerarySearchVenueList venueSelectHandler={venueSelectHandler} searchResultList={searchResultList}/>
                    </div>
                }              
                    
                </div>

                

                <div className='venue-search-detail-container search-venue-detail-desktop'>
                    {isShowingPlaceDetail &&
                    <ItinerarySearchVenueDetail
                        selectedVenue={selectedVenue}
                        setSelectedVenue={setSelectedVenue}
                        locationList = {locationList}
                        addToListHandler = {addToListHandler} 
                        closePlaceDetailHandler = {closePlaceDetailHandler}
                    />}
                </div>
            </div>
        </div>
    )
}


const Marker = ({ selectedVenue, locationList, setSelectedVenue }) => {
    const map = useMap();
    const markersRef = useRef({});
    const clusterer = useRef(null);

    useEffect(() => {
        if (!map) return;
        if (!clusterer.current) {
            clusterer.current = new MarkerClusterer({
                map,
                markers: Object.values(markersRef.current),
                renderer: {
                    render: ({ count, position }) => new google.maps.Marker({
                        icon: {
                            url: itinPoi,
                            scaledSize: new google.maps.Size(30, 30),
                        },
                        position,
                        zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
                    }),
                },
            });
        } else {
            clusterer.current.clearMarkers();
            clusterer.current.addMarkers(Object.values(markersRef.current));
        }
    }, [map, locationList]);

    const setMarkerRef = useCallback((marker, index) => {
        if (!marker && !markersRef.current[index]) return;
        if (marker && markersRef.current[index]) return;

        if (marker) {
            markersRef.current[index] = marker;
        } else {
            delete markersRef.current[index];
        }

        if (clusterer.current) {
            clusterer.current.clearMarkers();
            clusterer.current.addMarkers(Object.values(markersRef.current));
        }
    }, []);

    return (
        <>
            {
                selectedVenue && !locationList.find(item => item._id === selectedVenue._id) &&
                <AdvancedMarker position={{ lat: selectedVenue.location.latitude, lng: selectedVenue.location.longitude }}>
                    <img src={netPoi} width={25} height={25} />
                </AdvancedMarker>
            }
            {locationList.map((location, index) => (
                <AdvancedMarker
                    key={location._id}
                    position={{ lat: location.location.latitude, lng: location.location.longitude }}
                    onClick={() => setSelectedVenue(location)}
                    ref={(marker) => setMarkerRef(marker, index)}
                >
                    <p className='direction-advanced-marker'>{index + 1}</p>
                </AdvancedMarker>
            ))}
        </>
    );
};
// draggable component
const DraggableTable = ({
        locationList, setLocationList, deleteLocationHandler, 
        venueSelectHandler, setIsOptimising, itinerary,locationNameRef
    }) => {
    const { setLoading } = useContext(AuthContext);

    const getLocationPos = (id) =>{
        return locationList.findIndex(location => location._id === id)
    }

    const dragEndHandler = (event) =>{
        setIsOptimising(false);
        const {active, over} = event
        if(active.id === over.id){
            return
        }
        // setLocationList((locationList) =>{
        //     const originalPos = getLocationPos(active.id)
        //     const newPos = getLocationPos(over.id)
        //     return arrayMove(locationList, originalPos, newPos)
        // })

        setLoading(true)
        const originalPos = getLocationPos(active.id)
        const newPos = getLocationPos(over.id)
        let newList = arrayMove(locationList, originalPos, newPos)
        newList = newList.map((location)=> {return {_id: location._id}})
        itineraryService.updateItineraryPlaceList(itinerary, newList)
            .then((res) => {if(res){setLocationList(res.placeList)}})
            .finally(() => setLoading(false))
    }

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(TouchSensor),
        useSensor(KeyboardSensor,{
            coordinateGetter: sortableKeyboardCoordinates
        })
    )

    return (
        <DndContext collisionDetection={closestCorners} onDragEnd={dragEndHandler} sensors={sensors}>
        <div className='table-warper'>
            <table>
                <tbody>
                <SortableContext items={locationList} strategy={verticalListSortingStrategy}>
                    {locationList.map((location,index) => {
                    return (
                        <DraggableRow
                            key={location._id}
                            id={location._id}
                            location = {location}
                            index={index + 1}
                            deleteLocationHandler = {deleteLocationHandler}
                            venueSelectHandler = {venueSelectHandler}
                            locationNameRef = {locationNameRef}
                        />
                    )
                    })}
                </SortableContext>
                </tbody>
            </table>
        </div>
        </DndContext>
);
};

const DraggableRow = ({ id, index, location, deleteLocationHandler, venueSelectHandler,locationNameRef}) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

    const style = {
        transition,
        transform: CSS.Transform.toString(transform),
        willChange: 'transform'
    };

    return (
        <tr
        ref={setNodeRef}
        style={style}>
            {/* only click bui vien walking street for testing mock data */}
            {/* fix this css */}
            <td className='itinerary-location'>
                <div onClick={()=>{venueSelectHandler(location)}} className='location-name-and-index'>
                    <div className='location-index'>{index}</div>
                    <div className='location-name'ref={locationNameRef}>
                        {location.displayName.length >= 40 ? `${location.displayName.substring(0, 40)}...` : location.displayName}
                    </div>
                    
                </div>
                <FontAwesomeIcon
                    {...attributes}
                    {...listeners}
                    icon={faGripVertical} className='itinerary-location-icon'
                />
                <FontAwesomeIcon icon={faTrashCan} className='itinerary-trash-icon'onClick={()=>{deleteLocationHandler(location)}}/>
            </td>
        </tr>
    );
};



//route component
const Directions = ({
    locationList, setDirectionsRenderer, itinerary,
    directionsRenderer, route, setRoute, setLocationList,
    isOptimising, setIsOptimising, routeClickCount
}) => {

    const { setLoading } = useContext(AuthContext);
    const map = useMap();
    const routesLibrary = useMapsLibrary("routes");
    const [directionsService, setDirectionsService] = useState();

    const generateRoutesHandler = async () => {
        if (!directionsRenderer || !directionsService) {
            return;
        }
        //generate waypoints list
        var wayPointList = []
        locationList.map((venue,index) =>{
            if(index !== 0 && index !== (locationList.length -1)){
                wayPointList.push(
                    {location: {
                    lat: venue.location?.latitude,
                    lng: venue.location?.longitude
                },stopover: true})
            }
        })
        console.log("route: " + route)
        let routeObject = {
            origin: {
                lat: locationList[0].location?.latitude || null,
                lng: locationList[0].location?.longitude || null
            }, // or a valid address string
            waypoints: wayPointList,
            optimizeWaypoints: route === "optimize" ? true : false,
            // optimizeWaypoints: true,
            destination: {
                lat: locationList[locationList.length - 1].location?.latitude,
                lng: locationList[locationList.length - 1].location?.longitude
            },
            travelMode: google.maps.TravelMode.DRIVING,
            provideRouteAlternatives: false,
        }

        try {
            const response = await directionsService.route(routeObject);
            directionsRenderer.setDirections(response);

            if(route === "optimize"){
                // Extracting the reordered waypoints
                const reorderedWaypoints = response.routes[0].waypoint_order.map(index => locationList[index + 1]);
                console.log(response)
                console.log('Reordered Waypoints:', reorderedWaypoints);

                setIsOptimising(true)
                setRoute("normal")
                setLoading(true)
                let newList = [
                    locationList[0], // Start point
                    ...reorderedWaypoints,
                    locationList[locationList.length - 1] // End point
                ]
                newList = newList.map((location)=> {return {_id: location._id}})
                itineraryService.updateItineraryPlaceList(itinerary, newList)
                    .then((res) => {if(res){setLocationList(res.placeList)}})
                    .finally(() => setLoading(false))

            }
        } catch (error) {
            errorMessage("Cannot generate route");
        }
    };

    useEffect(() => {
        if (!map || !routesLibrary) return;
        setDirectionsService(new routesLibrary.DirectionsService());
        setDirectionsRenderer(new routesLibrary.DirectionsRenderer({ map: map ,suppressMarkers:true}));
    }, [map, routesLibrary]);

    useEffect(() => {
        if(!locationList[0]?.location || !locationList[1]?.location){
            if(directionsRenderer){
                directionsRenderer.setMap(null);
            }
            return
        }

        if (directionsService && directionsRenderer && !isOptimising && routeClickCount>0) {
            generateRoutesHandler();
        }
    }, [directionsService, directionsRenderer, locationList, routeClickCount]);

    return null;
};

export default ItineraryDetail