import './RecommendationComponentStyle.css'
import ReactDOM from 'react-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheck, faFilter, faMagnifyingGlass, faDownLeftAndUpRightToCenter, faUpRightAndDownLeftFromCenter} from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from 'react'
import { PRICE_LEVELS } from '../../constant.js'
import FilterModal from './FilterModal.js'
import venueImage from '../../asset/images/venue.png'
import { GG_IMG_URL_PREFIX, GG_IMG_URL_SUFFIX } from '../../constant.js'

const VenueList = ({ 
        onSelectVenue, venueListData, isRecommended, 
        setIsRecommended, searchText, handleSearchTextChange, 
        applyFilterHandler, isFiltering, toggleVenueListHandler, 
        isVenueListExtend
    }) =>{

    const [filterOPen, setFilterOpen] = useState(false);

    const toggleFilterModal = () =>{
        setFilterOpen(!filterOPen)
    }

    const isRecommendedHandler = () => {
        if(!isRecommended){
            setIsRecommended(true);
        }
    };

    const isAllHandler = () => {
        if(isRecommended){
            setIsRecommended(false);
        }
    };

    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            applyFilterHandler()
        }
    };
    

    return (
        <>
        <div className='venue-list-container'>
            <p className='venue-list-header'>
                <b>Venue</b>
                {isVenueListExtend ? 
                <FontAwesomeIcon icon={faDownLeftAndUpRightToCenter} className='venue-list-header-icon' onClick={toggleVenueListHandler}/>
                :
                <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} className='venue-list-header-icon' 
                onClick={toggleVenueListHandler}/>
                }
                </p>
            <table>
                <tbody>
                    <tr>
                        <td data-testid="all" onClick={isAllHandler} className={!isRecommended ? 'netcompany-green-color' : ''}>All</td>
                        <td data-testid="recommended" onClick={isRecommendedHandler} className={isRecommended ? 'netcompany-green-color' : ''}>Recommended</td>
                    </tr>
                </tbody>
            </table>
            <div className="search-and-filter">
                <div>
                    {isFiltering && <span className='filter-status-dot'></span>}
                    <FontAwesomeIcon className='venue-list-filter-icon' icon={faFilter} onClick={toggleFilterModal}/>
                </div>
                <div className='search-bar'>
                    <input type="text" placeholder='Search...' 
                        value={searchText} 
                        onChange={handleSearchTextChange} 
                        onKeyDown={handleEnter}
                        maxLength={20}
                    />
                    <FontAwesomeIcon className='venue-list-icon search-icon' icon={faMagnifyingGlass} onClick={applyFilterHandler}/>
                </div>
            </div>
            <div data-testid="venue-list" className='venue-list'>
                {venueListData ? venueListData
                    .filter((venue) => isRecommended ? venue.isRecommended : true)
                    .map((venue, index) => {
                        let label = ""
                        if(venue.primaryType){
                            var list = venue.primaryType.split("_")
                            list.map((word) =>{
                                label += word.charAt(0).toUpperCase() + word.slice(1) + " "
                            })
                        }
                        return (
                            <div
                                key={index}
                                className='venue-item'
                                data-testid='venue-item'
                                onClick={() => onSelectVenue(venue)}
                            >
                                <div className='image-container'>
                                    {venue.isRecommended ? 
                                    <img src={venue.photo || venueImage} alt="venue image" /> : 
                                    <img src={venue.photo ? GG_IMG_URL_PREFIX+venue.photo.name+GG_IMG_URL_SUFFIX : venueImage} alt="venue image" />}
                                </div>
                                <div className='venue-info'>
                                    <div className='venue-info-top'>
                                        {venue.isRecommended ?
                                        <p className='venue-name'><b><FontAwesomeIcon className='venue-list-icon' icon={faCheck} />{venue.displayName}</b> </p>
                                        :
                                        <p className='venue-name'><b>{venue.displayName}</b> </p>
                                        }
                                    </div>
                                    <div className='venue-info-bottom'>
                                        <p className='venue-category'>{label}</p>  
                                        <p className='venue-price-tag'>{PRICE_LEVELS[venue.priceLevel]}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    }) : <></>}
            </div>
            {filterOPen === true ? <div className='dim-screen'></div> : ""}
        </div>
        
        {filterOPen === true ? <div className='filter-modal-parent-container'><FilterModal toggleFilterModal={toggleFilterModal} 
                                            applyFilterHandler ={applyFilterHandler}
                                            isRecommended={isRecommended}/></div> : ""}
        </>
    );
};

export default VenueList;
