// User types
export const USER_TYPE_NORMAL_USER = "USER"
export const USER_TYPE_ADMIN = "ADMIN"

// API BASE URL
export const BASE_API_V1 = "api/v1/"

// Price levels
export const PRICE_LEVELS = {
    PRICE_LEVEL_UNSPECIFIED: "",
    PRICE_LEVEL_FREE: "₫",
    PRICE_LEVEL_INEXPENSIVE: "₫₫",
    PRICE_LEVEL_MODERATE: "₫₫₫",
    PRICE_LEVEL_EXPENSIVE: "4x₫",
    PRICE_LEVEL_VERY_EXPENSIVE: "5x₫"
}
export const PRICE_LEVELS_ARRAY = [
    "PRICE_LEVEL_FREE",
    "PRICE_LEVEL_INEXPENSIVE",
    "PRICE_LEVEL_MODERATE",
    "PRICE_LEVEL_EXPENSIVE",
    "PRICE_LEVEL_VERY_EXPENSIVE"
]


// Date time format
export const dateTimeFormat = 'dd/MM/yyyy HH:mm'
export const dateTimeFormatWithTimeZone = 'dd/MM/yyyy HH:mm xxx'
export const dateFormat = 'dd-MM-yyyy'

// Google maps image URL
export const GG_IMG_URL_PREFIX = "https://places.googleapis.com/v1/"
export const GG_IMG_URL_SUFFIX = "/media?key="+process.env.REACT_APP_GOOGLE_MAP_API_KEY+"&max_width_px=352"