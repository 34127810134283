import React from 'react';
import './SideBarStyle.css';

const Sidebar = ({ currentPage, onNavigate }) => {

    return (
        <div className="sidebar">
            <div 
                className={`sidebar-item ${currentPage === 'history' ? 'active' : ''}`} 
                onClick={() => onNavigate('history')}
            >
                <p className='desktop'>Activity Log</p>
                <p className='mobile'>Log</p>
            </div>
            <div 
                className={`sidebar-item ${currentPage === 'user' ? 'active' : ''}`} 
                onClick={() => onNavigate('user')}
            >
                User
            </div>
            <div 
                className={`sidebar-item ${currentPage === 'recommend' ? 'active' : ''}`} 
                onClick={() => onNavigate('recommend')}
            >
                Recommend
            </div>
            <div 
                className={`sidebar-item ${currentPage === 'team-event' ? 'active' : ''}`} 
                onClick={() => onNavigate('team-event')}
            >
                <p className='desktop'>Team Event</p>
                <p className='mobile'>Event</p>
            </div>
        </div>
    );
};

export default Sidebar;
