import './RecommendationComponentStyle.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faMapPin, faXmark, faChevronDown, faStreetView } from '@fortawesome/free-solid-svg-icons';
import venueImage from '../../asset/images/venue.png'
import { useState, useEffect, useRef } from 'react';
import { GG_IMG_URL_PREFIX, GG_IMG_URL_SUFFIX } from '../../constant.js'

const UnrecomendedVenueDetail = ({ selectedVenue, closeDetail, showRegisForm }) => {
    const handleRecommendClick = () => {
        showRegisForm(selectedVenue);
    };

    const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
    const d = new Date();
    var day = weekday[d.getDay()];

    const [toggleOpeningHour, setToggleOpeningHour] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const venueNameRef = useRef(null);
    const toggleOpeningHourHandler = () => {
        setToggleOpeningHour(!toggleOpeningHour);
    }

    useEffect(() => {
        const element = venueNameRef.current;
        if (element.scrollWidth > element.clientWidth) {
            setShowTooltip(true);
        } else {
            setShowTooltip(false);
        }
    }, [selectedVenue.displayName]);

    return (
        selectedVenue ?
        <div className="venue-detail-container">
            <div className='venue-detail-fixed-header'>
                <p className="venue-name" ref={venueNameRef}>
                    <b>{selectedVenue.displayName}</b>
                    {setShowTooltip && (
                        <span className='tooltip-text'>{selectedVenue.displayName}</span>
                    )}
                </p>
                <button className='venue-x-button' onClick={closeDetail} data-testid="close-button">
                    <FontAwesomeIcon icon={faXmark} />
                </button>
            </div>
            <img className="venue-image" src={selectedVenue.photo ? GG_IMG_URL_PREFIX+selectedVenue.photo.name+GG_IMG_URL_SUFFIX : venueImage} alt="venue" />
            <div className="venue-address">
                <FontAwesomeIcon className="venue-detail-icon mapPin-icon" icon={faMapPin} />
                <p>{selectedVenue.formattedAddress}</p>
            </div>
            {selectedVenue.openingHours ? 
                <div className={toggleOpeningHour?"venue-opening-hours-open":"venue-opening-hours"}>
                <FontAwesomeIcon className="venue-detail-icon clock-icon" icon={faClock} />
                {toggleOpeningHour ? 
                    <table className="table-normal recommended-table">
                        <tbody>
                            {selectedVenue.openingHours.map((hours, index) =>{
                                var hour = hours.split(": ");
                                return (
                                    <tr key={index}>
                                        <td className='opening-hour-day'>{hour[0]}</td>
                                        <td className={hour[1] == "Closed" ? "red-text" : ""}>{hour[1]}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                :
                    <table className="table-normal recommended-table">
                        <tbody>
                            {
                                selectedVenue.openingHours.filter(hours => hours.includes(day)).map((hours, index) =>{
                                    var hour = hours.split(": ");
                                    return (
                                        <tr key={index}>
                                            <td className='opening-hour-day'>{hour[0]}</td>
                                            <td className={hour[1] == "Closed" ? "red-text" : ""}>{hour[1]}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                }
                <FontAwesomeIcon className="venue-detail-icon arrow-down-icon" icon={faChevronDown} onClick={toggleOpeningHourHandler} />
            </div> : ""
            }
            
            <div className='venue-distance'>
                <FontAwesomeIcon className="venue-detail-icon" icon={faStreetView} />
                <p>{selectedVenue.distance}km</p>
            </div>
            <button className='venue-recommend-button' data-testid='venue-recommend-button' onClick={handleRecommendClick}>
                <b>Recommend</b>
            </button>
        </div>
        : ""
    );
};

export default UnrecomendedVenueDetail;
