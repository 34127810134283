import "./AdminActivityLogStyle.css"
import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../context/AuthContext.js';
import Pagination from "../shared/Pagination.js"
import { adminService } from '../../service/adminService.js';
import { dateTimeFormatWithTimeZone } from '../../constant.js'
import { format } from "date-fns"

const AdminActivitiLog = () =>{

  const [showPrintOptions, setShowPrintOptions] = useState(false);
  const [printStart, setPrintStart] = useState(1);
  const [printEnd, setPrintEnd] = useState(1);
  const { setLoading } = useContext(AuthContext);
  const [totalPages, setTotalPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [pageRangeDisplayed, setPageRangeDisplayed] = useState(1);
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    fetchLog(currentPage, itemsPerPage);

    const handleResize = () => {
      setPageRangeDisplayed(window.innerWidth <= 700 ? 1 : 2);
    };

    handleResize(); 
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [])
  const handlePrintCancel = () =>{
    setShowPrintOptions(false)
  }
  const handlePageClick = (event) => {
    fetchLog(event.selected + 1, itemsPerPage)
    setCurrentPage(event.selected + 1);
  };

  const fetchLog = async (page, perPage) => {
    setLoading(true)
    adminService.getLogs(page, perPage)
      .then((res) => { if (res) { 
        setLogs(res.logs); 
        setTotalPages(res.totalPage);
      }})
      .finally(() => setLoading(false));
  }

  const handlePrintStartChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value) && Number(value) > 0) {
      setPrintStart(Number(value));
    } else {
      setPrintStart("");
    }
  };
  
  const handlePrintEndChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value) && Number(value) > 0) {
      setPrintEnd(Number(value));
    } else {
      setPrintEnd("");
    }
  };

  const handlePrintClick = () => {
    setShowPrintOptions(!showPrintOptions);
  };

  const handlePrint = () => {
    adminService.printLog(1, 1)
  }

  const isPrintButtonDisabled = () => {
    const start = parseInt(printStart, 10);
    const end = parseInt(printEnd, 10);

    const isInvalidPageNumber = (num) => isNaN(num) || num <= 0;

    const isStartInvalid = isInvalidPageNumber(start) || start > totalPages;
    const isEndInvalid = isInvalidPageNumber(end) || end > totalPages;

    return isStartInvalid || isEndInvalid || start > end;
  };

  return(
    <div className='history-container'>
      <div className='history-header'>
        <div className='history-title'>Activity Log
        </div>
        <div className='print-options'>
          {showPrintOptions ? (
            <div className='print-input-page'>
              <label>Page to print:</label>
              <input 
                type='text' 
                placeholder='Start' 
                className='print-start' 
                value={printStart}
                onChange={handlePrintStartChange}
              />
              -
              <input 
                type='text' 
                placeholder='End' 
                className='print-end' 
                value={printEnd}
                onChange={handlePrintEndChange}
              />
              <button
                  className='cancel-print-button'
                  onClick={handlePrintCancel}
                >Cancel</button>
              <button 
                className='clicked-print-button' 
                onClick={handlePrint}
                disabled={isPrintButtonDisabled()}>
                Print
              </button>
              {/* {showPrintOptions &&
                <button
                  className='cancel-print-button'
                  onClick={handlePrintCancel}
                >Cancel</button>
              } */}
            </div>
          ) : (
            <button className='print-button' onClick={handlePrintClick}>
              <b>Print</b>
            </button>
          )}
        </div>
      </div>
      <Pagination 
        totalPages={totalPages} 
        pageRangeDisplayed={pageRangeDisplayed} 
        handlePageClick={handlePageClick} 
        currentPage={currentPage} 
      />
      <table className='history-table'>
        <thead>
          <tr>
            <th className="activity-log-time-col">Time</th>
            <th className="activity-log-userName-col">Username</th>
            <th className="activity-log-action-col">Action</th>
            <th className="activity-log-page-col">Page</th>
          </tr>
        </thead>
        <tbody>
          {logs.map((item, index) => (
            <tr key={index} className={index % 2 === 0 ? "hight-light-table-row":""}>
              <td data-label="Time"><p className="table-item">{format(new Date(item.createdAt), dateTimeFormatWithTimeZone)}</p></td>
              <td data-label="Username"><p className="table-item">{item.user.userName}</p></td>
              <td data-label="Action"><p className="table-item">{item.activity}</p></td>
              <td data-label="Page"><p className="table-item">{item.path}</p></td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination 
        totalPages={totalPages} 
        pageRangeDisplayed={pageRangeDisplayed} 
        handlePageClick={handlePageClick} 
        currentPage={currentPage} 
      />
    </div>
  )
}

export default AdminActivitiLog