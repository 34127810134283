import React from 'react';
import './LoadUserLoaderStyle.css';

function LoadUserLoader(){
    return(
        <div id='load-user-loader-container'>
            <div id='loader-container'>
                <div className="text-center">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoadUserLoader;