import React from 'react';
import { createBrowserRouter, Outlet, RouterProvider} from 'react-router-dom';

import AuthContextProvider from './context/AuthContext.js';
import UserRoute from './component/routes/UserRoute.js';
import Header from './component/shared/Header.js'
import Footer from './component/shared/Footer.js'
import NotFound from './component/shared/NotFound.js';
import { ToastContainer} from 'react-toastify';

import LandingPage from './page/LandingPage.js'
import MapPage from './page/MapPage.js';
import TeamEventPage from './page/TeamEventPage.js';
import ItineraryPage from './page/ItineraryPage.js';
import ProfilePage from './page/ProfilePage.js';
import AdminPage from './page/AdminPage.js';

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
          <div>
            <Header/>
            <Outlet/>
            <Footer/>
          </div>
      ),
      children:
        [
          {
            path: "/",
            element: (
              <LandingPage />
            )
          },
          {
            path: "/map",
            element: (
              <UserRoute>
                <MapPage />
              </UserRoute>
            )
          },
          {
            path:"/event",
            element:(
              <UserRoute>
                <TeamEventPage/>
              </UserRoute>
            )
          },
          {
            path:"/itinerary",
            element:(
              <UserRoute>
                <ItineraryPage/>
              </UserRoute>
            )
          },
          {
            path:"/admin",
            element:(
              <UserRoute>
                <AdminPage/>
              </UserRoute>)
          },
          {
            path:"/profile",
            element: (
              <UserRoute>
                <ProfilePage/>
              </UserRoute>)
          },
          {
            path: "/*",
            element: <NotFound/>
          }
        ]
    }
  ]);

    return (
      <div className="App">
        <AuthContextProvider>
          <RouterProvider router={router}>
          </RouterProvider>
          <ToastContainer />
        </AuthContextProvider>
      </div>
    );
}
export default App;