import './RecommendationComponentStyle.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faClock, faMapPin, faStreetView, faXmark, faCheck, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import React, { useContext, useState, useEffect,useRef } from 'react';
import { dateTimeFormat } from '../../constant.js';
import { format } from 'date-fns';
import profileImg from '../../asset/images/profile.png';
import venueImage from '../../asset/images/venue.png';
import { AuthContext } from '../../context/AuthContext';

const RecommendedVenueDetail = ({ 
    selectedVenue, closeDetail, showHostEventForm, 
    setEditMode, openCommentModal, setCurrentComment,
    showItineraryForm
}) => {
    const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const d = new Date();
    var day = weekday[d.getDay()];

    const { authState } = useContext(AuthContext);
    const [toggleOpeningHour, setToggleOpeningHour] = useState(false);
    const [toggleComment, setToggleComment] = useState(true);
    const [showMenu, setShowMenu] = useState({state: false, commentId: null});
    const [showTooltip, setShowTooltip] = useState(false);
    const venueNameRef = useRef(null);

    const toggleOpeningHourHandler = () => {
        setToggleOpeningHour(!toggleOpeningHour);
    };
    const toggleCommentHandler = () => {
        setToggleComment(!toggleComment);
    };

    const toggleMenuHandler = (commentId) => {
        setShowMenu({state: !showMenu.state, commentId: commentId});
    };
    
    const handleAddComment = (recomId) => {
        openCommentModal();
        setCurrentComment({recomId});
    }

    const handleEditComment = (recomId, comment) => {
        openCommentModal();
        setEditMode(true); 
        setCurrentComment({recomId, comment});
        setShowMenu({state: !showMenu.state, commentId: null});
    }

    useEffect(() => {
        const element = venueNameRef.current;
        if (element.scrollWidth > element.clientWidth) {
            setShowTooltip(true);
        } else {
            setShowTooltip(false);
        }
    }, [selectedVenue.displayName]);

    return (
        selectedVenue ?
        <div className="venue-detail-container">
            <div className="venue-detail-fixed-header">
                <p className="venue-name" ref={venueNameRef}>
                    <b>
                        {selectedVenue.isRecommended && <FontAwesomeIcon className="venue-detail-tick" icon={faCheck} />}
                        {" "+selectedVenue.displayName}
                    </b>
                    {showTooltip ? <span className="tooltip-text">{selectedVenue.displayName}</span> : ""}
                </p>
                <button className="venue-x-button" onClick={closeDetail} data-testid="close-button">
                    <FontAwesomeIcon icon={faXmark} />
                </button>
            </div>
            <img className="venue-image" src={selectedVenue.photo || venueImage} alt="venue image" />
            <div className="venue-address">
                <FontAwesomeIcon className="venue-detail-icon mapPin-icon" icon={faMapPin} />
                <p>{selectedVenue.formattedAddress}</p>
            </div>
            {selectedVenue.openingHours.length > 0 && (
                <div className={toggleOpeningHour ? "venue-opening-hours-open" : "venue-opening-hours"}>
                    <FontAwesomeIcon className="venue-detail-icon clock-icon" icon={faClock} />
                    {toggleOpeningHour ? 
                        <table className="table-normal recommended-table">
                            <tbody>
                                {selectedVenue.openingHours.map((hours, index) => {
                                    var hour = hours.split(": ");
                                    return (
                                        <tr key={index}>
                                            <td className="opening-hour-day">{hour[0]}</td>
                                            <td className={hour[1] === "Closed" ? "red-text open-hours" : "open-hours"}>{hour[1]}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    :
                        <table className="table-normal recommended-table">
                            <tbody>
                                {selectedVenue.openingHours.filter(hours => hours.includes(day)).map((hours, index) => {
                                    var hour = hours.split(": ");
                                    return (
                                        <tr key={index}>
                                            <td className="opening-hour-day">{hour[0]}</td>
                                            <td className={hour[1] === "Closed" ? "red-text" : ""}>{hour[1]}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    }
                    <FontAwesomeIcon className="venue-detail-icon arrow-down-icon" icon={faChevronDown} onClick={toggleOpeningHourHandler} />
                </div>
            )}
            <div className="venue-distance">
                <FontAwesomeIcon className="venue-detail-icon" icon={faStreetView} />
                <p>{selectedVenue.distance}km</p>
            </div>
            <p className="comment-header">Comment</p>
            <div className="comment-section">
                {toggleComment? 
                    <div className="comment-item">
                        <div className="comment-item-top">
                            <div className="commment-item-top-user">
                                <img className="user-img" src={selectedVenue.comments[0].user.photoUrl || profileImg} alt="user pfp" />
                                <div>
                                    <p className="user-name">{selectedVenue.comments[0].user.userName}</p>
                                    <p className="comment-time">{format(new Date(selectedVenue.comments[0].time), dateTimeFormat)}</p>
                                </div>
                            </div>
                            {selectedVenue.comments[0].user._id === authState.user._id && (
                                <div className="menu-container">
                                    <button className="three-dots" onClick={()=>toggleMenuHandler(selectedVenue.comments[0]._id)}>
                                        <FontAwesomeIcon icon={faEllipsisH} />
                                    </button>
                                    <div className={`dropdown-menu ${showMenu.state && showMenu.commentId===selectedVenue.comments[0]._id ? 'show' : ''}`}>
                                        <button onClick={() => handleEditComment(selectedVenue._id, selectedVenue.comments[0])}>Edit</button>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="comment-item-bottom">
                            <p>{selectedVenue.comments[0].content}</p>
                        </div>
                    </div> 
                    :
                    selectedVenue.comments.map((comment, index) => {
                        return (
                            <div className="comment-item" key={index}>
                                <div className="comment-item-top">
                                    <div className="commment-item-top-user">
                                        <img className="user-img" src={comment.user.photoUrl || profileImg} alt="user pfp" />
                                        <div>
                                            <p className="user-name">{comment.user.userName}</p>
                                            <p className="comment-time">{format(new Date(comment.time), dateTimeFormat)}</p>
                                        </div>
                                    </div>
                                    {comment.user._id === authState.user._id && (
                                    <div className="menu-container">
                                        <button className="three-dots" onClick={()=>toggleMenuHandler(comment._id)}>
                                            <FontAwesomeIcon icon={faEllipsisH} />
                                        </button>
                                        <div className={`dropdown-menu ${showMenu.state && showMenu.commentId===comment._id ? 'show' : ''}`}>
                                            <button onClick={() => handleEditComment(selectedVenue._id, comment)}>Edit</button>
                                        </div>
                                    </div>
                                    )}
                                </div>
                                <div className="comment-item-bottom">
                                    <p>{comment.content}</p>
                                </div>
                            </div>
                        );
                    })
                } 

                
                <div className="comment-section-toggle">
                    {selectedVenue.comments.length > 1 ?
                    <p onClick={toggleCommentHandler}>{toggleComment?"Show more Comments" : "Show less"}</p> : ""}
                </div>

            </div>
            <div className="venue-detail-buttons">
                <div className="venue-dertail-button-top">
                    <button className="venue-detail-button" onClick={() => handleAddComment(selectedVenue._id)}><b>Add Comment</b></button>
                    <button className="venue-detail-button" onClick={showItineraryForm}><b>Add Itinerary</b></button>
                </div>
                <button className="venue-detail-button host-event-button" onClick={showHostEventForm}><b>Host Event</b></button>
            </div>
        </div>
        : ""
    )
}

export default RecommendedVenueDetail;
