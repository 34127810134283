import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Navbar, NavbarBrand, NavItem, Nav, Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./SharedComponentStyle.css";
import logo from '../../asset/images/logo.png';
import { FaUser } from 'react-icons/fa';
import { AuthContext } from '../../context/AuthContext.js';
import mobileLogo from '../../asset/images/Logo_mobile.png'

function Header() {
  const { authState, microsoftLogin, logOut } = useContext(AuthContext);
  const [userNameList, setUserNameList] = useState(null);

  useEffect(() => {
    const list = authState.user?.userName.split(" ");
    setUserNameList(list);
  }, [authState]);

  return (
    <header className='header'>
      <Navbar className="navbar">
        <NavLink className='logo' to="/"><img src={logo} alt="Logo"></img></NavLink>
        <NavLink className='logo_mobile' to="/"><img src={mobileLogo} alt="Logo"></img></NavLink>
        <Nav className="navigation-buttons">
          {authState.isAuthenticated && (
            <>
              <NavItem>
                <NavLink className='navLink' to="/map">Map</NavLink>
              </NavItem>
              <NavItem>
                <NavLink className='navLink' to="/event">Event</NavLink>
              </NavItem>
              <NavItem>
                <NavLink className='navLink' to="/itinerary">Itinerary</NavLink>
              </NavItem>
            </>
          )}
        </Nav>
        
        {authState.isAuthenticated ? (
          <Nav className='header-right-buttons'>
            <NavItem>
              {authState.user?.type !== 'ADMIN'?
                <>
                  <NavLink className='user user-short-name' to="/profile" data-testid="user-icon" >
                    {
                      authState.user.photoUrl?
                        <img className="icon" src={authState.user.photoUrl} alt="user pfp" />:
                        <FaUser className='icon'/>
                    }{userNameList?(userNameList[0]+ (userNameList[1]? (" "+userNameList[1][0]):"")):"User"}
                  </NavLink>
                  <NavLink className='user user-full-name' to="/profile" data-testid="user-icon" >
                    {
                      authState.user.photoUrl?
                        <img className="icon" src={authState.user.photoUrl} alt="user pfp" />:
                        <FaUser className='icon'/>
                    }{userNameList? (userNameList[0]+ (userNameList[1]? (" "+userNameList[1]):"")) : "User"}
                  </NavLink>
                </> :
                <Dropdown id='dropdown-menu'>
                  <Dropdown.Toggle className='user user-short-name' id="dropdown-basic" style={{border:'none'}}>
                    {
                      authState.user.photoUrl?
                        <img className="icon" src={authState.user.photoUrl} alt="user pfp" />:
                        <FaUser className='icon'/>
                    }{userNameList?(userNameList[0]+ (userNameList[1]? (" "+userNameList[1][0]):"")):"User"}
                  </Dropdown.Toggle>
                  <Dropdown.Toggle className='user user-full-name' id="dropdown-basic" style={{border:'none'}}>
                    {
                      authState.user.photoUrl?
                        <img className="icon" src={authState.user.photoUrl} alt="user pfp" />:
                        <FaUser className='icon'/>
                    }{userNameList? (userNameList[0]+ (userNameList[1]? (" "+userNameList[1]):"")) : "User"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item as={NavLink} to="/profile" className='dropdown-item'>
                      Profile Page
                    </Dropdown.Item>
                    {authState.user?.type === 'ADMIN' && (
                      <Dropdown.Item as={NavLink} to="/admin"  className='dropdown-item'>
                        Admin Page
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              }
            </NavItem>
            <NavItem>
              <NavLink className='logout-button' href="#" onClick={logOut}>
                Log out
              </NavLink>
            </NavItem>
          </Nav>
        ) : (
          <Nav>
            <NavItem>
              <NavLink className='login-button' href="#" onClick={microsoftLogin}>
                Log in
              </NavLink>
            </NavItem>
          </Nav>
        )}
      </Navbar>
    </header>
  );
}

export default Header;
