import './HostEventFormStyle.css'
import React, { useState, useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark, faFile } from '@fortawesome/free-solid-svg-icons'
import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker, DatePicker } from '@mui/x-date-pickers';
import { AuthContext } from '../../context/AuthContext.js';
import { eventService } from '../../service/eventService.js';
import { useNavigate } from "react-router-dom";

import dayjs from 'dayjs';

const HostEventForm = ({ selectedVenue, closeHostEventForm }) => {
    const [eventName, setEventName] = useState('');
    const [description, setDescription] = useState('');
    const [selectedDate, setSelectedDate] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [eventThumbnail, setEventThumbnail] = useState(null);
    const [timeError, setTimeError] = useState(false);
    
    const { authState, setLoading } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleEventNameChange = (e) => setEventName(e.target.value);

    const handleDescriptionChange = (e) => setDescription(e.target.value);

    const handleThumbnailChange = (e) => {
        setEventThumbnail(e.target.files[0]);
    }

    const todayDate = dayjs().startOf('day');

    const isFormfilled = () => {
        return (
            eventName.trim() !== '' &&
            selectedDate !== null &&
            startTime !== null &&
            endTime !== null &&
            eventThumbnail !== null &&
            !timeError
        );
    };

    const handleStartTimeChange = (newValue) => {
        setStartTime(newValue);
        if (endTime && newValue && newValue.isAfter(endTime)) {
            setTimeError(true);
        } else {
            setTimeError(false);
        }
    };

    const handleEndTimeChange = (newValue) => {
        setEndTime(newValue);
        if (startTime && newValue && newValue.isBefore(startTime)) {
            setTimeError(true);
        } else {
            setTimeError(false);
        }
    };

    const handleSubmit = async () => {
        setLoading(true);
        const editDate = dayjs(selectedDate)
        const editStartTime = dayjs(startTime);
        const editEndTime = dayjs(endTime);

        const dtoStartTime = editDate.set("hour", editStartTime.get('hour')).set("minute", editStartTime.get('minute')).format()
        const dtoEndTime = editDate.set("hour", editEndTime.get('hour')).set("minute", editEndTime.get('minute')).format()

        eventService.hostEvent({
            name: eventName,
            description: description,
            place: selectedVenue._id,
            image: eventThumbnail,
            startTime: dtoStartTime,
            endTime: dtoEndTime
        }).then((res) => { 
            if (res) { navigate("/event") } 
        }).finally(() => setLoading(false));
    };

    return (
        <div className="host-event-form-modal-container">
            <div className="host-event-form-modal-content">
                <button className='host-event-form-modal-x-button' onClick={closeHostEventForm}>
                    <FontAwesomeIcon icon={faXmark} />
                </button>
                <div className="host-event-form-modal-title">Host at <div>{selectedVenue.displayName}</div></div>
                <div className="host-event-form-host-name-container">
                    <div className="host-event-form-host-name-title">Host: {authState.user.userName}</div>
                </div>
                <input 
                    type="text" 
                    className="host-event-form-event-name" 
                    placeholder="Event title" 
                    maxLength="70"
                    value={eventName}
                    onChange={handleEventNameChange}
                />
                <textarea 
                    className="host-event-form-event-description" 
                    placeholder="Description" 
                    rows="4"
                    cols="30"
                    maxLength="1000"
                    value={description}
                    onChange={handleDescriptionChange}
                ></textarea>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div className="host-event-form-date-picker-container">
                        <DatePicker
                            label="Event Date"
                            value={selectedDate}
                            minDate={todayDate}
                            onChange={(newValue) => setSelectedDate(newValue)}
                            renderInput={(params) => <TextField {...params} />}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    border: '1px solid #ddd',
                                    borderRadius: '20px'
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none'
                                }
                            }} 
                        />
                    </div>
                    <div className="host-event-form-time-picker-container">
                        <TimePicker
                            label="Start Time"
                            value={startTime}
                            onChange={handleStartTimeChange}
                            renderInput={(params) => <TextField {...params} />}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    border: '1px solid #ddd',
                                    borderRadius: '20px'
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none'
                                }
                            }}
                            slotProps={{
                                popper: {
                                    sx: {
                                    "& .MuiMenuItem-root": {
                                        "&.Mui-selected": {
                                            backgroundColor: "#123836",  
                                        },
                                        "&.Mui-selected:hover": {
                                            backgroundColor: "#123836",  
                                        },
                                    },
                                    },
                                },
                            }}
                        />
                        <p className='time-tilde'>~</p>
                        <TimePicker
                            label="End Time"
                            value={endTime}
                            onChange={handleEndTimeChange}
                            renderInput={(params) => <TextField {...params} />}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    border: '1px solid #ddd',
                                    borderRadius: '20px'
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none'
                                }
                            }} 
                            slotProps={{
                                popper: {
                                    sx: {
                                    "& .MuiMenuItem-root": {
                                        "&.Mui-selected": {
                                            backgroundColor: "#123836",  
                                        },
                                        "&.Mui-selected:hover": {
                                            backgroundColor: "#123836",  
                                        },
                                    },
                                    },
                                },
                            }}
                        />
                    </div>
                    {timeError && <div className="time-error-message">End time must be after start time.</div>}
                </LocalizationProvider>
                <div className="host-event-form-thumbnail-container">
                    <label className="host-event-form-thumbnail-title">Event thumbnail</label>
                    <input 
                        type="file" 
                        className="host-event-form-thumbnail-input" 
                        accept=".png,.jpg,.jpeg,.heic,.heif"
                        id="event-thumbnail"
                        onChange={handleThumbnailChange}
                    />
                    <label htmlFor="event-thumbnail" className="host-event-form-thumbnail-button">
                        <FontAwesomeIcon icon={faFile} style={{ marginRight: '8px' }} />
                        {eventThumbnail ? "Selected!" : "Choose file"}
                    </label>
                </div>
                <button 
                    className="create-event-button" 
                    onClick={handleSubmit}
                    disabled={!isFormfilled()}
                >Create event</button>
            </div>
        </div>
    )
}

export default HostEventForm;