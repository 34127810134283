import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { PRICE_LEVELS } from '../../constant.js';
import venueImage from '../../asset/images/venue.png';
import { GG_IMG_URL_PREFIX, GG_IMG_URL_SUFFIX } from '../../constant.js';

const SurroundedVenueList = ({ onSelectVenue, venueListData, isBackButtonClick }) => {
    const handleBackButton = () => {
        if (isBackButtonClick) {
            isBackButtonClick();
        }
    };

    return (
        <div className='surronded-venue-list-container'>
                <div className='venue-list-back-button'>
                    <button onClick={handleBackButton}>
                        <FontAwesomeIcon className='venue-list-back-button-icon' icon={faChevronLeft} />
                    </button>
                    <p className='surronded-venue-list-header'><b>Surrounded Venue</b></p>
                </div>
            <div data-testid="venue-list" className='venue-list'>
                {venueListData ? venueListData
                    .map((venue, index) => {
                        let label = ""
                        if(venue.primaryType){
                            var list = venue.primaryType.split("_")
                            list.map((word) =>{
                                label += word.charAt(0).toUpperCase() + word.slice(1) + " "
                            })
                        }
                        return (
                            <div
                                key={index}
                                className='venue-item'
                                data-testid='venue-item'
                                onClick={() => onSelectVenue(venue)}
                            >
                                <div className='image-container'><img src={venue.photo ? GG_IMG_URL_PREFIX + venue.photo.name + GG_IMG_URL_SUFFIX : venueImage} alt="venue image" /></div>
                                <div className='venue-info'>
                                    <div className='venue-info-top'>
                                        <p className='venue-name'><b>{venue.displayName && venue.displayName.length >= 15 ? `${venue.displayName.substring(0, 22)}...` : venue.displayName}</b></p>
                                    </div>
                                    <div className='venue-info-bottom'> 
                                        <p className='venue-price-tag'>{venue.distance + "km"}</p>
                                    </div>
                                    <div className='venue-info-bottom'>
                                        <p className='venue-category'>{venue.primaryType && label.length >= 24 ? `${label.substring(0, 23)}...` : label}</p>
                                        <p className='venue-category-mobile'>{venue.primaryType && label.length >= 11 ? `${label.substring(0, 10)}...` : label}</p>
                                        <p className='venue-price-tag'>{PRICE_LEVELS[venue.priceLevel]}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    }) : <></>}
            </div>
        </div>
    );
};

export default SurroundedVenueList;