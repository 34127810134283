import './ItinerarySearchVenueDetailStyle.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faClock, faMapPin, faStreetView, faXmark, faCheck } from '@fortawesome/free-solid-svg-icons'
import { useState,useRef,useEffect } from 'react'
import { dateTimeFormat } from '../../constant.js'
import { format } from "date-fns"
import profileImg from '../../asset/images/profile.png';
import venueImage from '../../asset/images/venue.png'


const ItinerarySearchVenueDetail = ({
    selectedVenue, setSelectedVenue, locationList, 
    addToListHandler, closePlaceDetailHandler
}) => {

    const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
    const d = new Date();
    var day = weekday[d.getDay()];
    const venueNameRef = useRef(null);
    const [showTooltip, setShowTooltip] = useState(false);
    

    const [toggleOpeningHour, setToggleOpeningHour] = useState(false);
    const [toggleComment, setToggleComment] =useState(true)

    const toggleOpeningHourHandler = () => {
        setToggleOpeningHour(!toggleOpeningHour);
    }
    const toggleCommentHandler = () =>{
        setToggleComment(!toggleComment)
    }
    useEffect(() => {
        const element = venueNameRef.current;
        if (element.scrollWidth > element.clientWidth) {
            setShowTooltip(true);
        } else {
            setShowTooltip(false);
        }
    }, [selectedVenue.displayName]);

    
    const isVenueInList = locationList?.some(location => location._id === selectedVenue._id);

    return(
        <div className="itinerary-venue-detail-container">
        <div className='venue-detail-fixed-header'>
            <p className="venue-name" ref={venueNameRef}>     
                <b>
                {selectedVenue.isRecommended && <FontAwesomeIcon className="venue-detail-tick" icon={faCheck} />}
                {" "+selectedVenue.displayName}
                </b>
                {showTooltip ? <span className="tooltip-text">{selectedVenue.displayName}</span> : ""}
            </p>
            
            <button className='venue-x-button'>
                <FontAwesomeIcon icon={faXmark} onClick={closePlaceDetailHandler} />
            </button>
        </div>
        <img className="venue-image" src={selectedVenue.photo || venueImage} alt="venue image" />
        <div className="venue-address">
            <FontAwesomeIcon className="venue-detail-icon mapPin-icon" icon={faMapPin} />
            <p>{selectedVenue.formattedAddress}</p>
        </div>
        {selectedVenue.openingHours.length > 0 &&
            <div className={toggleOpeningHour?"venue-opening-hours-open":"venue-opening-hours"}>
            <FontAwesomeIcon className="venue-detail-icon clock-icon" icon={faClock} />
            {toggleOpeningHour ? 
                <table className="table-normal recommended-table">
                    <tbody>
                        {selectedVenue.openingHours.map((hours, index) =>{
                            var hour = hours.split(": ");
                            return (
                                <tr key={index}>
                                    <td className='opening-hour-day'>{hour[0]}</td>
                                    <td className={hour[1] == "Closed" ? "red-text open-hours" : "open-hours"}>{hour[1]}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            :
                <table className="table-normal recommended-table">
                    <tbody>
                        {   selectedVenue.openingHours &&
                            selectedVenue.openingHours.filter(hours => hours.includes(day)).map((hours, index) =>{
                                var hour = hours.split(": ");
                                return (
                                    <tr key={index}>
                                        <td className='opening-hour-day'>{hour[0]}</td>
                                        <td className={hour[1] == "Closed" ? "red-text" : ""}>{hour[1]}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            }
            <FontAwesomeIcon className="venue-detail-icon arrow-down-icon" icon={faChevronDown} onClick={toggleOpeningHourHandler} />
            </div>
        }
        {selectedVenue.distance &&
            <div className='venue-distance'>
                <FontAwesomeIcon className="venue-detail-icon" icon={faStreetView} />
                <p>{selectedVenue.distance}km</p>
            </div>
        }
        <p className='comment-header'>Comment</p>
        <div className='comment-section'>
            {toggleComment? 
                <div className='comment-item'>
                    <>
                    <div className='comment-item-top'>
                        <div className='commment-item-top-user'>
                            <img className='user-img' src={selectedVenue.comments[0].user.photoUrl || profileImg} alt="user pfp" />
                            <p>{selectedVenue.comments[0].user.userName}</p>
                        </div>
                        <p className='comment-time'>{format(new Date(selectedVenue.comments[0].time), dateTimeFormat)}</p>
                    </div>
                    <div className='comment-item-bottom'>
                        <p>{selectedVenue.comments[0].content}</p>
                    </div>
                    </>
                   
                </div> 
                :
                selectedVenue.comments.map(comment => {
                    return (
                        <div className='comment-item'>
                            <div className='comment-item-top'>
                                <div className='commment-item-top-user'>
                                    <img className='user-img' src={comment.user.photoUrl || profileImg} alt="user pfp" />
                                    <p>{comment.user.userName}</p>
                                </div>
                                <p className='comment-time'>{format(new Date(comment.time), dateTimeFormat)}</p>
                            </div>
                            <div className='comment-item-bottom'>
                                <p>{comment.content}</p>
                            </div>
                        </div>
                    )
                })
                }
            <div className='comment-section-toggle'>
                {selectedVenue.comments && selectedVenue.comments.length > 1 ?
                 <p onClick={toggleCommentHandler}>{toggleComment?"Show more Comments" : "Show less"}</p> : ""}
               
            </div>
        </div>
        
        <div className='venue-detail-buttons'>
            {!isVenueInList &&
                <div className='add-to-list-button'onClick={addToListHandler}>add to list</div>
            }
        </div>
    </div>
)
    
}

export default ItinerarySearchVenueDetail
