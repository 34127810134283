import './EventDetailComponent.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft,faPlus,faUserPlus,faUserCheck,faUser,faMapPin,faClock, faEdit, faTrashCan, faX} from '@fortawesome/free-solid-svg-icons'
import { APIProvider, Map, AdvancedMarker, Pin } from '@vis.gl/react-google-maps';
import React, { useContext, useState, useEffect } from 'react';
import { eventService } from '../../service/eventService.js';
import { AuthContext } from '../../context/AuthContext.js';
import { dateTimeFormatWithTimeZone } from '../../constant.js'
import { format } from "date-fns"
import netPoi from '../../asset/images/netPoi.png';
import JoinEventModal from './JoinEventModal.js';

const EventDetailComponent = ({eventId, backButtonHandler, fetchEvents}) =>{
    const { setLoading, authState } = useContext(AuthContext);
    const [isFullList,setIsFullList] = useState(false)
    const [event, setEvent] = useState(null)
    const [showJoinModal, setShowJoinModal] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [participantRemove, setParticipantRemove] = useState(null);
    const [isEventPast, setIsEventPast] = useState(false);

    const registerListToggle = () =>{
        setIsFullList(!isFullList)
    }

    const handleEventModal = () => {
        setShowJoinModal(!showJoinModal);
    };

    const handleCloseJoinModal = (isCancel = true) => {
        setShowJoinModal(false);
        if(!isCancel){
            fetchEventDetail(eventId)
        }
    }

    const handleEditModeToggle = () => {
        if (!isEventPast){
            setIsEditMode(!isEditMode);
        }
    }

    const handleRemoveParticipant = (participant) => {
        setParticipantRemove(participant);
    }

    const confirmRemoveParticipant = async () => {
        if (participantRemove) {
            setLoading(true);
            eventService.removeParticipant(event._id, participantRemove.user._id)
            .then(result => {
                fetchEventDetail(eventId);
            })
            .finally(() => {
                setLoading(false);
                setParticipantRemove(null);
            });
        }
    }

    const cancelRemoveParticipant = () => {
        setParticipantRemove(null)
    }

    useEffect(() => {
        fetchEventDetail(eventId);
    }, []);

    const fetchEventDetail = async (id) => {
        setLoading(true);
        eventService.getEventDetail(id)
            .then((res) => { 
                if (res) { setEvent(res); } 
                else { backButtonHandler(); fetchEvents(); }
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (event) {
            const currentTime = new Date();
            const eventStartTime = new Date(event.startTime);
            setIsEventPast(currentTime >= eventStartTime);
        }
    }, [event]);

    const reducedRegisterList = [];
    if(event?.registerList){
        for(let i = 0; i < event.registerList.length; i++){
            if(i==3 && event.registerList.length > 4){
                reducedRegisterList.push(<div className='event-participant'>...</div>)
				break;
            } else {
                reducedRegisterList.push(
                <div className='event-participant'>
                {event?.registerList[i].user.userName}
                {isEditMode && <FontAwesomeIcon icon={faTrashCan} className='event-trash-icon' onClick={() => handleRemoveParticipant(event.registerList[i])} />}
                </div>)
            }
        }
    }
    
    return(
        event ?
        <div className='event-detail-container'>
            <div className='event-detail-info-and-map'>
                <div className='event-detail-info'>
                    <div className='event-name desktop'>
                        <div className="event-detail-back-btn" onClick={backButtonHandler}><FontAwesomeIcon icon={faChevronLeft}/>Back</div>
                        {event.name}
                    </div>
                    <table>
                        <tbody>
                            <tr>
                                <td className='event-detail-icon'><FontAwesomeIcon icon={faUser} /></td>
                                <td className='event-detail-text'>{event?.createdBy.userName}</td>
                            </tr>
                            <tr>
                                <td className='event-detail-icon' rowSpan={2}><FontAwesomeIcon icon={faMapPin} /></td>
                                <td className='event-detail-text'>{event.place.displayName}</td>
                            </tr>
                            <tr>
                                <td className='event-detail-text'>{event.place.formattedAddress}</td>
                            </tr>
                            <tr>
                                <td className='event-detail-icon' rowSpan={2}><FontAwesomeIcon icon={faClock} /></td>
                                <td className='event-detail-text'>{format(new Date(event.startTime), dateTimeFormatWithTimeZone)}</td>
                            </tr>
							<tr>
                                <td className='event-detail-text'>{format(new Date(event.endTime), dateTimeFormatWithTimeZone)}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className='participant-header-container'>
                        <p className='participant-header'>Participant:</p>
                        {authState.user._id === event.createdBy._id && !isEventPast && (
                            <div className={`edit-participant-button-container ${isEditMode ? 'cancel-edit-color' : 'netcompany-color'}`} onClick={handleEditModeToggle}>
                                <button className='edit-participant-button'> 
                                    <FontAwesomeIcon className='btn-icon' icon={isEditMode? faX : faEdit} /> 
                                    {isEditMode ? 'Cancel' : 'Edit'}
                                </button>
                            </div>
                        )}
                    </div>
                    <div className='event-participant-list'>
                        {isFullList ? 
                            event?.registerList.map((participant) =>{
                                return(<div className='event-participant'>{participant.user.userName}</div>)     
                                }) 
                            
                        :
                            <>
                                {reducedRegisterList}
                            </>    
                        }
                    </div>
                    <div className='event-detail-buttons'>
                        {event?.registerList.length > 4 ?
							<button className='view-full-list-btn desktop' onClick={registerListToggle}><FontAwesomeIcon icon={faPlus} />{isFullList ? " Show less" : " Show more"} </button>
							: ""
						}
                        {event?.registerList.length > 4 ?
                                <button className='view-full-list-btn mobile' onClick={registerListToggle}><FontAwesomeIcon icon={faPlus} />{isFullList ? " Show less" : " Show more"} </button>
                                : ""
                        }
                        {!isEventPast && (event.registerList.some(participant => participant.user._id === authState.user._id) ? 
                        <button className='join-event-button disable'><FontAwesomeIcon icon={faUserCheck} /> You Joined!</button>
                        :
                        <button className='join-event-button' onClick={handleEventModal}><FontAwesomeIcon icon={faUserPlus}/> Join event</button>
                        )} 
                    </div> 
                    <div className='event-detail-decription'>
                        <p className='description-header'>Description:</p>
                        <div className='event-detail-destiption-textArea'>
                            <p className='event-detail-text'>{event?.description}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='event-detail-map'>
                    <div className='event-name mobile'>
                        <div className="event-detail-back-btn" onClick={backButtonHandler}><FontAwesomeIcon icon={faChevronLeft}/>Back</div>
                        {event?.name}
                        </div>
                    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY} libraries={[]} >
                        <Map 
                            defaultZoom={15}
                            defaultCenter={{ lat: event?.place.location.latitude, lng: event?.place.location.longitude }}
                            disableDefaultUI
                            mapId={process.env.REACT_APP_MAP_ID}
                            className='event-detail-inner-map' >
                                <AdvancedMarker 
                                    position={{ lat: event?.place.location.latitude, lng: event?.place.location.longitude }}
                                    title={event.place.displayName}>
                                    	<img src={netPoi} width={30} height={30} />
                                </AdvancedMarker>  
                        </Map>
                    </APIProvider>
                </div>
            {showJoinModal && <JoinEventModal event={event} closeModal={handleCloseJoinModal} />}
            {participantRemove && (
                <div className='remove-participant-container'>
                    <div className='remove-participant-modal'>
                        <div className='remove-participant-modal-title'>Remove Participant from Event</div>
                        <div className='remove-participant-modal-desc'>Are you sure you want to remove <strong>{participantRemove.user.userName}</strong> from <strong>{event.name}</strong> ?</div>
                        <div className='remove-participant-modal-button'>
                            <button className='remove-participant-button' onClick={confirmRemoveParticipant}>Remove</button>
                            <button className='cancel-remove-participant-button'onClick={cancelRemoveParticipant}>Cancel</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
        : ""
        
    )
}
export default EventDetailComponent