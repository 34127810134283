import React, {useEffect, useState, useContext} from 'react';
import { AuthContext } from '../../context/AuthContext.js';
import "./UserModalStyle.css";
import { adminService } from '../../service/adminService.js';
import { dateTimeFormat } from '../../constant.js';
import { format } from 'date-fns';

function UserModal({ closeUserModal, id }) {
  const { setLoading } = useContext(AuthContext);
  const [user, setUser] = useState(null)

  useEffect(() => {
    fetchUserDetail()
  }, []);

  const fetchUserDetail = async () => {
    setLoading(true)
    adminService.getUserDetail(id)
      .then((res) => {
        if(res){
          setUser(res)
        }
      })
      .finally(() => setLoading(false));
  }

  return (
    <div className='admin-user-modal-container'>
      <div className='admin-user-modal-inner-container'>
        <div className='admin-user-modal'>
          <div className='admin-user-modal-header'>
            <h1 className='admin-user-header'>{user?.userName}</h1>
            <span className='admin-user-modal-close' onClick={closeUserModal}>&times;</span>
          </div>
          {user ?
            <div className='admin-user-info'>
              <div className='admin-user-name'>
                <h5>User name:</h5>
                <p>{user.userName}</p>
              </div>
              <div className='admin-user-email'>
                <h5>Email:</h5>
                <p>{user.email}</p>
              </div>
              <div className='admin-user-type'>
                <h5>Type:</h5>
                <p>{user.type}</p>
              </div>
              <div className='admin-user-created-date'>
                <h5>Date Time:</h5>
                <p>{format(new Date(user.createdAt), dateTimeFormat)}</p>
              </div>
            </div>
            : 
            ""
          }
        </div>
      </div>
    </div>
  );
}

export default UserModal;
