import './JoinEventModalStyle.css'
import React, { useContext } from 'react';
import { eventService } from '../../service/eventService.js';
import { AuthContext } from '../../context/AuthContext.js';
import { successMessage, errorMessage } from '../../service/toastService.js';

const JoinEventModal = ({ event, closeModal }) => {
    const { setLoading, authState } = useContext(AuthContext);
    
    const handleJoin = async () => {
        setLoading(true);
        eventService.register(event._id, authState.user._id)
        .finally(() => {
            setLoading(false);
            closeModal(false);
        });
    };

    return (
        <div className="join-modal-container">
            <div className="join-modal-content">
                <div className="join-modal-title">Join this event</div>
                <div className="join-modal-desc">You are joining in <strong>{event.name}</strong> event, and it cannot be canceled.</div>
                <div className="join-modal-buttons">
                    <button className="modal-button join-button" onClick={handleJoin}>Join</button>
                    <button className="modal-button cancel-button" onClick={closeModal}>Cancel</button>
                </div>
            </div>
        </div>
    )
};

export default JoinEventModal;